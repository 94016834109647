import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getData, getCode } from "country-list";
import AddClient from "../../components/client/AddClient";
import DisplayClient from "../../components/client/DisplayClient";
import "./adminManager.css";
import HTTPService from "../../Services/HTTPService";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import { GetErrorHandlingRoute, GetErrorKey } from "../../Utils/Common";
import Loader from "../../components/Loader/Loader";
import CustomToast from "../../Utils/Toast/Toast";
import ContactUs from "../../components/contact/ContactUs";
import { Navigate, useNavigate } from "react-router-dom";
import AddAdmin from "../../components/super-user/AddAdmin";
import DisplayAdmin from "../../components/super-user/DisplayAdmin";

const AdminManager = (props) => {
  const [allAdmin, setAllAdmin] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [AdminNameErrorMessage, setAdminNameErrorMessage] = useState(null);
  const [reRender, setRender] = useState(false);
  const [AdminEmailErrorMessage, setAdminEmailErrorMessage] =
    useState(null);
  const [AdminDescriptionErrorMessage, setAdminDescriptionErrorMessage] =
    useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [clearState, setClearState] = useState(0);
  // state for loder
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  let bodyFormData = new FormData();

  const toggleToast = () => {
    setShowToast(!showToast);
  };

  const toggleErrorToast = () => {
    setShowErrorToast(!showErrorToast);
  };

  const checkJwtAuth = () => {};

  const resetErrorMessage = () => {
    setAdminNameErrorMessage(null);
    setAdminEmailErrorMessage(null);
    setAdminDescriptionErrorMessage(null);
  };

  const addAdmin = (data, e) => {
    // set all error message null
    setAdminNameErrorMessage(null);
    setAdminEmailErrorMessage(null);
    setAdminDescriptionErrorMessage(null);

    // console.log("data", data);


      let payload = {
        first_name: data.name,
        email: data.email,
        description: data.description,
        created_by: JSON.parse(localStorage.getItem("userId")),
        role: "admin_user"
      };
      let url = UrlConstants.base_url + "users/user/";
      setLoader(true);

      HTTPService("POST", url, payload, false, true, false)
        .then((res) => {
          console.log("adding admin user res", res);
          //   on successeful responce display toast
          if (res.status === 201) {
            setToastMessage("New Admin is added successfully.");
            setShowErrorToast(false);
            toggleToast();
            getAllAdmin();
            setClearState(clearState + 1);
            setLoader(false);
            return;
          }

          return;
        })
        .catch((err) => {
          console.log("err", err);
          let returnValues = GetErrorKey(err, Object.keys(payload));
          let errorKeys = returnValues[0];
          let errorMessages = returnValues[1];

          if (errorKeys.length > 0) {
            for (var i = 0; i < errorKeys.length; i++) {
              switch (errorKeys[i]) {
                case "first_name":
                  setAdminNameErrorMessage(errorMessages[i]);

                  break;
                case "description":
                  setAdminDescriptionErrorMessage(errorMessages[i]);
                  break;
                case "email":
                  setAdminEmailErrorMessage(errorMessages[i]);
                  break;

                default:
                  //   history.push(GetErrorHandlingRoute(e));
                  // console.log("err in default");
                  navigate(GetErrorHandlingRoute(err));
                  break;
              }
            }
            setLoader(false);
          } else {
            // setToastMessage("Oops, something went wrong!");
            // setShowErrorToast(true);
            setLoader(false);
            navigate(GetErrorHandlingRoute(err));
          }
          //   print all error message
        });
    }
  

  //   create a funtion to get all client
  const getAllAdmin = () => {
    let url = UrlConstants.base_url + "users/user/";
    setLoader(true);
    HTTPService("GET", url, false, false, true, false)
      .then((res) => {
        // console.log("res in get all client", res);
        if (res.status === 200) {
          setAllAdmin(res.data);
          setClearState(true);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        // setToastMessage("Oops, something went wrong!");
        // setShowErrorToast(true);
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      });
  };

  const sendingActivityStatus = (status, admin) => {
    console.log("admin admin",admin)
    let url =
      UrlConstants.base_url + UrlConstants.user_status_toggle_end_point;
    let payload = {
      id: admin.id,
      is_active: status,
    };
    setLoader(true);
    HTTPService("PUT", url, payload, false, true, "")
      .then((res) => {
        // console.log("res in get all client", res);
        setLoader(false);
        if (res.status === 201) {
          setAllAdmin(res.data);
          setRender(!reRender);
          // setToastMessage("User status changed successfully.");
          // setShowErrorToast(false);
          // updateAllClientWthLatestStatus(res.data)
          // toggleToast();
          // setShowToast(true);
          // return true
        }
      })
      .catch((err) => {
        console.log("err", err);
        // setToastMessage("Oops, something went wrong!");
        // setShowErrorToast(true);
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      });
  };
  console.log("all admin", allAdmin,reRender)
  //   on page load call getAllAdmin funtion
  useEffect(() => {
    getAllAdmin();
    setClearState(0);
  }, []);

  return (
    <>
      {showToast ? (
        <CustomToast
          toggleToast={toggleToast}
          text={toastMessage}
          success={true}
        />
      ) : showErrorToast ? (
        <CustomToast
          toggleToast={toggleErrorToast}
          text={toastMessage}
          error={true}
        />
      ) : null}
      {loader ? <Loader /> : null}
      {/* {props.isContact ? <ContactUs setIsContact={props.setIsContact}/> : */}
      <Container className="client-manager-container">
        <Row xs={12} sm={12} md={12} lg={12}>
          <Col>
            <AddAdmin
              clearState={clearState}
              addAdmin={addAdmin}
              AdminNameErrorMessage={AdminNameErrorMessage}
              AdminEmailErrorMessage={AdminEmailErrorMessage}
              AdminDescriptionErrorMessage={AdminDescriptionErrorMessage}
              resetErrorMessage={resetErrorMessage}
            />
            <DisplayAdmin
              sendingActivityStatus={sendingActivityStatus}
              allAdmin={allAdmin}
            />
          </Col>
        </Row>
      </Container>
      {/* } */}
    </>
  );
};

export default AdminManager;
