import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import "./toast.css";

const CustomToast = (props) => {
  const [show, setShow] = useState(true);

  let timer;

  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(
    () => {
      props.toggleToast();
    },
    props.time ? props.time : 15000
  );

  return (
    <Row className="toast-row">
      <Col xs={6} md={12} lg={12} xl={12}>
        <Toast
        style={{width: "1040px"}}
          className={
            props.error
              ? "error-toast"
              : props.succes
              ? "success-toast"
              : "success-toast"
          }
          // sx={{
          //   border: `1px dashed ${props.color ? props.color : "red"}`,
          //   backgroundColor: props.color ? props.backgroundColor : "#30572a",
          // }}
          onClose={() => setShow(false)}
          show={show}
          delay={props.time ? props.time : 15000}
          autohide
          //   className="toast-container"
        >
          <div className="toast-header">
            <div className="toast-header-title">
              {props.text ? props.text : "Add some text"}
            </div>
            <button
              // style={{
              //   border: `1px solid ${props.color ? props.color : "#30572a"}`,
              //   backgroundColor: props.backgroundColor
              //     ? props.backgroundColor
              //     : "#30572a",
              // }}
              type="button"
              className={
                props.error
                  ? "btn-close error-btn-close"
                  : props.success
                  ? "btn-close success-btn-close"
                  : "btn-close success-btn-close"
              }
              aria-label="Close"
              data-dismiss="toast"
              onClick={() => {
                props.toggleToast();
                console.log("false");
              }}
            ></button>
          </div>
        </Toast>
      </Col>
    </Row>
  );
};

export default CustomToast;
