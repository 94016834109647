// Create a table to show activity logs of users using react bootstrap
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
// import paginationFactory from 'react-bootstrap-table2-paginator';
import Typography from "@mui/material/Typography";
import "../../Utils/common.css";
import "./activityLogs.css";
import { Row, Col, Container, CloseButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RegexConstants from "../../Utils/Constants/RegexConstants";

const { SearchBar, ClearSearchButton } = Search;

const ActivityLogs = ({ activityLogsData, getCanvasData }) => {
  const [activityLogs, setActivityLogs] = useState([]);

  // console.log("activityLogs", activityLogsData);
  let navbar = useNavigate();

  let data = activityLogsData.map((item, index) => {
    return {
      id: index + 1,
      name: item?.user?.first_name,
      email: item?.user?.email,
      sessionId: item.id,
      loginTime: item.login_time,
      logoutTime: item.logout_time,
      clientName: item?.user?.client,
      activityDescription: "view",
    };
  });

  useEffect(() => {
    setActivityLogs(data);
    
  }, [activityLogsData]);

  const columns = [
    {
      dataField: "id",
      text: "Sl.No",
            // sort: true,
    },
    {
      dataField: "name",
      text: "User Name",
            // sort: true,
      // onSort: (field, order) => {
      //  console.log("on sort", field,order)
      // }
    },
    {
      dataField: "email",
      text: "Mail ID",
            // sort: true,
    },
    {
      dataField: "clientName",
      text: "Client Name",
            // sort: true,
    },
    {
      dataField: "loginTime",
      text: "Login Time",
            // sort: true,
    },
    {
      dataField: "logoutTime",
      text: "Logout Time",
            // sort: true,
    },
    {
      dataField: "activityDescription",
      text: "Activity Description",
    },
  ];
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      //   console.log("clicked",e.target, row,rowIndex)
      if (e.target.innerText === "view") {
        // handleShow()
        getCanvasData(row.sessionId);
      }
    },
  };

  const MySearch = (props) => {
    const [searchText, setSearchText] = useState("");
    let input;

    const handleClick = (e) => {
      e.preventDefault()
      props.onSearch("");
      setSearchText("");
    };

    const handleChange = (e) => {
      e.preventDefault()
      let valueArray = e.target.value.split(" ");
      let extraSpace = true;

      let twoSpaceRegex = RegexConstants.TWO_SPACE_REGEX
      if(e.target.value[0]!==" "){
        setSearchText(e.target.value.replace(twoSpaceRegex," "))
      }

    //   console.log("valueArray" ,valueArray)
    //   for (let i in valueArray) {
    //     if (valueArray[i - 1] === "") {
    //       extraSpace = false;
    //       break;
    //     }
    //   }
    //   if (extraSpace) {
    //     setSearchText(e.target.value);
        props.onSearch(input.value);
    //   }
    };
  //   function onClickOfEnter() {
  //     if (window.event.keyCode == 13) {
  //         // console.log("ENTER PRESSED");
  //         window.event.preventDefault();
  //         window.event.stopPropagation();
  //         return false;
  //     }
  // }
  

    return (
      <>
        {/* <form> */}
          <div className="custom-input">
            <input
              id="search-bar-0"
              className="form-control"
              style={{ backgroundColor: "#ffffff" }}
              ref={(n) => (input = n)}
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder="Filter"
              value={searchText}
              // onKeyDown={onClickOfEnter}
            />
            <input
              id="input-btn-close"
              className="btn-close"
              type="reset"
              value=""
              onClick={(e) => handleClick(e)}
            />
          </div>
        {/* </form> */}
      </>
    );
  };

  const onTableChange = (type, newState) => {
    console.log("on table change",type,newState)
    let sortedData = newState.data.sort((a,b)=>  {
       
      return a.name.localeCompare(b.name)
    })
    let filteredData = newState.data.map((item, index) => {
      return {
        id: index + 1,
        name: item?.name,
        email: item?.email,
        sessionId: item.sessionId,
        loginTime: item.loginTime,
        logoutTime: item.logoutTime,
        clientName: item?.clientName,
        activityDescription: "view",
      };
    });

    setActivityLogs(filteredData)
  }

  return (
    <div>
      <ToolkitProvider
        keyField="id"
        data={activityLogs}
        columns={columns}
        search
      >
        {(props) => (
          <Container className="main-container">
            <Row>
              <Row className="">
                <Col xl={2} md={4} sm={12} xs={12}>
                  <Typography className="titleText" variant="h4">
                    Activity Log
                  </Typography>
                </Col>
                <Col xl={8} md={12} sm={12} xs={12}>
                  {/* <SearchBar placeholder="Filter" srText="" { ...props.searchProps } /> */}
                  <MySearch {...props.searchProps} />
                  {/* <CloseButton onClick={ () => props.searchProps.onSearch('') }/> */}
                </Col>
              </Row>
              {/* <hr /> */}
              {/* <ExportCSVButton { ...props.csvProps }>Export CSV</ExportCSVButton> */}
              {/* <hr /> */}
              <BootstrapTable
                  stickyHeader
                className="activity_log_table_container"
                rowEvents={rowEvents}
                {...props.baseProps}
                
              />
            </Row>
          </Container>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ActivityLogs;
