import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import "./displayUser.css";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import SwitchKey from "../../toggle-button/Switch";

const DisplayUser = ({ allUser, sendingActivityStatus }) => {
  console.log("display user",);
  const [clientData, setClientData] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const toggleMore = () => {
    setShowMore(!showMore);
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "black",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "black",
    },
  }));
  function popupOverlayHandler() {
    let div = document.getElementById("popover-basic");
    console.log(div)
    if (div) div.classList.remove("show")
  }

  useEffect(() => {
    setClientData([...allUser]);
  }, [allUser]);

  console.log("clientData", clientData);

  let descriptionClass = "description-column";
  return (
    <Container className="display-client-container">
      <Row>
        <Col sx={12} sm={12} md={12} xl={12}>
          <Typography className="titleText" variant="h4">
            User Details
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col sx={12} sm={12} md={12} xl={12}>
          <TableContainer component={Paper} onScroll={() => popupOverlayHandler()} className="table-container">
            {clientData.length > 0 && (
              <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="tablehead_bg_green">
                  <TableRow>
                    <TableCell width="10%">Sl.No</TableCell>
                    <TableCell width="18%" align="left">
                      Name
                    </TableCell>
                    <TableCell width="26%" maxWidth="30%" align="left">
                      User ID
                    </TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell width="12%" align="left">
                      Active/Inactive
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientData?.map((user, index) => (
                    <TableRow
                      key={index}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell style={{wordBreak: "break-all"}} align="left">{user?.first_name}</TableCell>
                      <TableCell style={{wordBreak: "break-all", width: "20%"}} align="left" >{user?.email}</TableCell>
                      <TableCell
                        className="description-column"
                        width="46%"
                        minWidth="46% !impotent"
                        align="left"
                      >
                        {user.description?.length > 40 ? (
                          // && showMore === false
                          <span>
                            {user?.description.substring(0, 29)}...
                            <span
                              style={{ color: "blue" }}
                              onClick={toggleMore}
                            >
                              <BootstrapTooltip title={user?.description}>
                                <span>more</span>
                              </BootstrapTooltip>
                            </span>
                          </span>
                        ) : (
                          // : client.description.length > 50 &&
                          //   showMore === true ? (
                          //   <span>
                          //     {client.description}{" "}
                          //     <span
                          //       style={{ color: "blue" }}
                          //       onClick={toggleMore}
                          //     >
                          //       {" "}
                          //       less{" "}
                          //     </span>
                          //   </span>
                          // )
                          user.description
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {/* {user.is_active ? "Active" : "Incative"} */}
                        <SwitchKey eachUser={user} user={user} sendingActivityStatus={sendingActivityStatus} status={user.is_active} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default DisplayUser;
