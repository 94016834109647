
import React, { useEffect } from "react"
import { Routes, Route, useNavigate } from "react-router-dom";
import Footer from "../components/footer/footer";

import UserNavbar from "../components/navbar/UserNavbar";
import DataDownload from "../views/user/DataDownload";
import { isLoggedInUserNormal } from "../Utils/Common";
import { getTokenLocal } from "../Utils/Common";
import { useState } from "react";
import ContactUs from "../components/contact/ContactUs";
import UserProfile from "../components/user/UserProfile";
import UserLogs from "../views/user/ActivityLogs";



const UserRoutes = (props) => {

    const navigate = useNavigate()
    const [isContact, setIsContact] = useState(false)

    const isUserLoggedInAsNormal = () => {
        console.log(isLoggedInUserNormal())
        if (getTokenLocal() && isLoggedInUserNormal()) {
            navigate("/user/datadownload")
        }
        else {
            navigate("/login")
        }
    }

    useEffect(() => {
        isUserLoggedInAsNormal()
    }, [])
    // console.log('Hello I am herew')

    return (
        <>
            <div className="center_keeping_conatiner">
                <>
                    <UserNavbar />
                    {
                        isContact ? <ContactUs isContact={isContact} setIsContact={setIsContact} /> :
                        <Routes>
                            <Route path="/profile" element={<UserProfile/>}/>
                            <Route path="/datadownload" element={<DataDownload />} />
                            <Route path="logs" element={<UserLogs/>} />
                        </Routes>
                    }
                    <Footer isContact={isContact} setIsContact={setIsContact} />
                </>
            </div>
        </>
    )
}
export default UserRoutes