import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styles from "./canvaslogs.module.css"
function OffCanvasUserLogs({ show, handleClose, handleShow, canvasData, ...props }) {
    

    // let {session,activity_logs} = canvasData;
    // let {login_time, logout_time, user} = session;
    // let {first_name, client, email} = user;
    console.log("canvas data",canvasData)

    // console.log("canvasData", canvasData, first_name, email, client, login_time, logout_time,activity_logs)

  
    // to use this component onCick handleShow function should be called

    return (
        <>
            {/* <Button variant="primary" onClick={handleShow} className="me-2">
                {name}
            </Button> */}
            <Offcanvas className={styles.canvas} show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton style={{marginRight: "79px",} } >
                    <Offcanvas.Title style={{ width: "690px", marginLeft: "106px", textAlign: "left" }}>Activity Description</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{margin: "0 106px", width: "722px"}} >
                    <Container style={{ paddingLeft: "0px"}}  >
                        <hr className={styles.hrLine} />


                        <Row spacing={2}>
                            <Col xs={3} lg={3} md={3} sm={3}>
                                {/* <div className={styles.eachDetailBox} >
                                    <span style={{marginTop: "5px"}}   >User Name</span>
                                    <span style={{marginTop: "5px"}}  >{canvasData?.session?.user.first_name}</span>
                                </div> */}
                                <div className={styles.eachDetailBox} >
                                    <span style={{marginTop: "5px"}} >Login Time</span>
                                    <span style={{marginTop: "5px"}} >{canvasData?.session?.login_time}</span>
                                </div>
                            </Col>
                            <Col xs={3} lg={3} md={3} sm={3}>
                                {/* <div className={styles.eachDetailBox}  >
                                    <span style={{marginTop: "5px"}} >Mail ID</span>
                                    <span style={{marginTop: "5px",overflowWrap: "break-word"}} >{canvasData?.session?.user.email}</span>
                                </div> */}
                                <div className={styles.eachDetailBox} >
                                    <span style={{marginTop: "5px"}} >Logout Time</span>
                                    <span style={{marginTop: "5px"}} >{canvasData?.session?.logout_time}</span>
                                </div>
                            </Col>
                            {/* <Col xs={3} lg={3} md={3} sm={3}>
                                <div className={styles.eachDetailBox} >
                                    <span style={{marginTop: "5px"}} >Client Name</span>
                                    <span style={{marginTop: "5px"}} >{canvasData?.session?.user.client}</span>
                                </div>

                            </Col> */}
                        </Row>
                        <hr className={styles.hrLine} />

                        <Row style={{ width: "722px", margin: "auto", textAlign: "left" }}>Description</Row>
                        {
                           canvasData?.activity_logs?.map((activity, index) => {
                                return (
                                    <>
                                    <Row style={{ minWidth: "722px", maxWidth: "722px" }} spacing={2}>
                            {/* <Col xs={12} lg={12} md={3} sm={3}> */}
                            <div className={styles.eachDescription}>{canvasData?.session?.user.first_name} downloded "{activity.crop_data?.crop_name} data”, the download link is below.</div>

                            {/* </Col> */}
                        </Row>
                        <Row style={{ width: "700px" }}>

                            {/* <Col  xs={12} lg={12} md={3} sm={3}> */}
                            <div className={styles.eachDescription}>
                                <span style={{marginTop: "5px"}}  className={styles.linkLabel}>Link : </span> <a target="_blank" href={activity.crop_data?.download_url}>{activity.crop_data?.download_url} </a>
                            </div>

                            {/* </Col> */}
                            {/* <span style={{marginTop: "5px"}} >
                        </span> */}
                        </Row>
                        </>
                        )
                            })
                        }
                        
                    </Container>
                </Offcanvas.Body>

            </Offcanvas>
        </>
    );
}
export default OffCanvasUserLogs;

// // function Example() {
//     // return (
//         <>
//             {/* {['end'].map((placement, idx) => ( */}
//             <OffCanvasLogs key={idx} placement={"end"} name={placement} />
//             {/* ))} */}
//         </>
//     );
// }

// render(<Example />);