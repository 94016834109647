import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./views/home/Home";
import HomeNavBar from "./components/home/HomeNavBar";
import AdminRoutes from "./layout/Admin";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/login/Login";
import Success from "./components/Success/Success";
import ChangePassword from "./components/login/ChangePassword";
import UserRoutes from "./layout/User";
import ContactUs from "./components/contact/ContactUs";
import SessionExpired from "./components/Error/SessionExpired";
import Error from "./components/Error/Error";
import ResetLinkExpired from "./components/Error/ResetLinkExpired";
import ForgotPassword from "./components/login/ForgotPassword";
import SuperUserRoutes from "./layout/SuperUser";

const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "red",
        "&$error": {
          color: "blue",
        },
      },
    },
  },
});



function App() {


  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {/* <Home/> */}
          <Routes>
            <Route path="/" element={<Navigate to={"/home"} />} />
            <Route path="/home" element={<Home />} />
            {/* <Route path="/reset_password/:userid/:token" element={<Login firstTimeLogin={false} resetLinkFromMail={true} />} /> */}
            <Route path="/reset_password/:userid/:token" element={<ChangePassword resetChangePassword={true} />} />
            <Route path="admin/*" element={<AdminRoutes />} />
            <Route path="user/*" element={<UserRoutes />} />
            <Route exact path="superuser/*" element={<SuperUserRoutes />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot_password" element={<ForgotPassword />} />
            <Route exact path="/change_password" element={<ChangePassword enforceChangePassword={true} />} />
            <Route exact path="/success" element={<Success btntext="ok" imageText="Reset link sent to your mail id" msg="Please go to your mail and hit the link." heading="Password update" />} />
            <Route exact path="/contact" element={<ContactUs />} />
            <Route exact path="/reset_link_expired" element={< ResetLinkExpired />} />
            <Route exact path="/sessionexpired" element={<SessionExpired />} />
            <Route exact path="/error" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
