import React from "react";
import { Routes, Route} from "react-router-dom";
import AdminNavbar from "../components/navbar/AdminNavbar";
import Footer from "../components/footer/footer";
import ClientManager from "../views/admin/ClientManager";
import { useState, useEffect } from "react";
import { isLoggedInUserAdmin, getTokenLocal } from "../Utils/Common";
import { useNavigate } from "react-router-dom";
import UserManager from "../views/admin/UserManager";
import ContactUs from "../components/contact/ContactUs";
import Logs from "../views/admin/Logs";
import Settings from "../views/admin/Setting";

const AdminRoutes = (props) => {
  const navigate = useNavigate();
  const [isContact, setIsContact] = useState(false)

  const ifUserLoggedInAsAdmin = () => {
    if (getTokenLocal() && isLoggedInUserAdmin()) {
      navigate("/admin/clientmanager");
    } else {
      // flushLocalstorage()
      navigate("/login");
    }
  };
  useEffect(() => {
    ifUserLoggedInAsAdmin();
  }, []);

  return (
    <>
      <div className="center_keeping_conatiner">
       
            <AdminNavbar />
              {isContact ? <ContactUs isContact={isContact} setIsContact={setIsContact} /> :
              <Routes>
                <Route path="clientmanager" element={<ClientManager />} />
                <Route path="usermanager" element={<UserManager />} />
                <Route path="logs" element={<Logs/>} />
                <Route path="settings" element={<Settings/>} />
              </Routes>}
            <Footer isContact={isContact} setIsContact={setIsContact} />
        </div>
      {/* <Footer /> */}
    </>
  );
};

export default AdminRoutes;
