import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Table, TableCell, TableBody, TableHead, TableRow, Paper, TableContainer, Typography } from "@mui/material";
import HTTPService from "../../Services/HTTPService";
import { useNavigate } from "react-router-dom";
import { GetErrorHandlingRoute } from "../../Utils/Common";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import Loader from "../../components/Loader/Loader";
import importantIcon from "../../Assets/Image/importantIcon.svg"
import CustomToast from "../../Utils/Toast/Toast";
import "./dataDownload.css";
export default function DataDownload(props) {
    const [getDataDownloadList, setGetDataDownloadList] = useState([]);
    const [si, setSi] = useState("");
    const [cropname, setCropName] = useState("")
    const [season, setSeason] = useState("")
    const [period, setPeriod] = useState("")
    const [year, setYear] = useState("")
    const [action, setAction] = useState("")
    const [isLoader, setIsLoader] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);

    const navigate = useNavigate()

    const getDataDownload = () => {
        setIsLoader(true)
        HTTPService(
            "GET",
            UrlConstants.base_url + UrlConstants.main + "crop_data/",
            false,
            false,
            true
        ).then((response) => {
            setIsLoader(false);
            console.log("data download response", response);
            if (response.status == 200) {
                // setSi(response.data.id)
                // setCropName(response.data.crop_name);
                // setSeason(response.data.season)
                // setPeriod(response.data.period)
                // setYear(response.data.year)
                // setAction(response.data.data_url)
                setGetDataDownloadList(response.data);
            }
            // let datadownloadlist = getDataDownloadList
            // let datafromrequest = [...datadownloadlist, ...response.data];
            // console.log(datadownloadlist);
            // setGetDataDownloadList(datafromrequest)
        }).catch((e) => {
            console.log("error", e);
            setIsLoader(false)
            navigate(GetErrorHandlingRoute(e));
        })
    }
    const handleDownload = (id) => {
        console.log("id", id)
        // setToastMessage("The download link has been sent to your register mail id");
        // setShowToast(true);
        let payload = {
            session: JSON.parse(localStorage.getItem("sessionId")),
            crop_data: id,
        }

        setIsLoader(true);
        HTTPService(
            "POST",
             UrlConstants.base_url +"main/download_doc/",
            payload,
            false,
            true,
            false
        ).then((response) => {
            // Show toast
            setIsLoader(false);
            console.log("data download response", response);
            if (response.status == 201) {
                setToastMessage("The download link has been sent to your register mail id");
                setShowToast(true);
                
            }
        }).catch((e) => {
            console.log("error", e);
            setIsLoader(false)
            // setToastMessage("Something went wrong")
            // setShowErrorToast(true)
            navigate(GetErrorHandlingRoute(e));
        }
        )
    }


        

    const toggleToast = () => {
        setShowToast(false);
        setShowErrorToast(false); 
    };
    // const toggleErrorToast = () => {
    //       };

    useEffect(() => {
        getDataDownload();
    }, []);

    console.log("data download list", getDataDownloadList);
    console.log("ShowToast", showToast)

    return (
        <>
        
        {isLoader && <Loader />}
        {showToast && <CustomToast text={toastMessage} success={true} toggleToast={toggleToast} />}
        {showErrorToast && <CustomToast text={toastMessage}  error={true} toggleToast={toggleToast} />}

            <Container className="display-client-container">

<Row>
  <Col sx={12} sm={12} md={12} xl={12}>
    <Typography className="titleText" variant="h4" style={{marginLeft: "96px", marginTop: "30px"}}>
      Crop Data
    </Typography>
  </Col>
</Row>
<Row>
  <Col sx={12} sm={12} md={12} xl={12}>
                 {isLoader ? <Loader/> : null}
                 <Typography>
                <TableContainer component={Paper} style={{ width: "1040px", height: "483px", boxSizing: "border-box", border: "1px solid #30572A", borderRadius: "10px", marginLeft: "96px", marginTop: "20px", marginBottom: "106px" }}>
                    <Table stickyHeader >
                        <TableHead className="tablehead_bg_green">
                            <TableRow>
                                <TableCell>
                                    SI.No
                                </TableCell>
                                <TableCell>
                                    Crop Name
                                </TableCell>
                                <TableCell>
                                    Season
                                </TableCell>
                                {/* <TableCell>
                                    Period
                                </TableCell>
                                <TableCell>
                                    Year
                                </TableCell> */}
                                <TableCell>
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getDataDownloadList.map((row, index) => (
                                <TableRow
                                    key={index}
                                >
                                    <TableCell>{index+1}</TableCell>
                                    <TableCell>{row.crop_name}</TableCell>
                                    <TableCell>{row.season}</TableCell>
                                    {/* <TableCell>{row.period}</TableCell>
                                    <TableCell>{row.year}</TableCell> */}
                                    <TableCell className="data_download_cell"
                                    //  style={{color: "#2351F5", textDecoration: "underline", cursor: "pointer"}} 
                                     onClick={ (e) => handleDownload(row.id)} >
                                        {/* {row.data_url} */}
                                        {
                                            console.log("image",require("../../Assets/Image/downlode-logo.svg"))
                                        }
                                        <img src={require("../../Assets/Image/downlode-logo.svg").default} alt="download" style={{width: "22px", height: "22px"}}/>
                                       Download data
                                        </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div>
                <img
                style={{marginLeft :  "96px"}}
                src={importantIcon}
                alt="important"
                />
                <ol style={{marginLeft: "110px", marginTop: "-20px"}}>
                    <li>Hit the 'Download' Button to get respective data</li>
                    <li>The 'Download Link' for the Google drive document will be sent to registered mail</li>
                </ol>
                </div>

            
            </Typography>
        </Col>
        </Row>
        </Container>

   </>
    )
}
    
