import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import "./displayAdmin.css";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Switch from "../toggle-button/Switch";
import HTTPService from "../../Services/HTTPService";
import SwitchKey from "../toggle-button/Switch";
// import PasswordCanvas from "./canvas/PasswordCanvas";

export default function DisplayAdmin({ allAdmin, sendingActivityStatus }) {
  const [AdminData, setAdminData] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const toggleMore = () => {
    setShowMore(!showMore);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "black",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "black",
    },
  }));

  function popupOverlayHandler() {
    let div = document.getElementById("popover-basic");
    console.log(div);
    if (div) div.classList.remove("show");
  }


  useEffect(() => {
    setAdminData([...allAdmin]);
  }, [allAdmin]);
  let descriptionClass = "description-column";
  return (
    <Container className="display-client-container">
      <Row>
        <Col sx={12} sm={12} md={12} xl={12}>
          <Typography className="titleText" variant="h4">
            Admin Details
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col sx={12} sm={12} md={12} xl={12}>
          <TableContainer
            component={Paper}
            onScroll={() => popupOverlayHandler()}
            className="table-container"
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead className="display_admin_tablehead tablehead_bg_green">
                <TableRow>
                  <TableCell width="10%">Sl.No</TableCell>
                  <TableCell width="18%" align="left">
                    Name
                  </TableCell>
                  <TableCell width="24%" align="left">
                    User ID
                  </TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell width="12%" align="left">
                    Active/Inactive
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="display_admin_tablebody">
                {AdminData.map((admin, index) => (
                  <TableRow
                    key={index}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{admin.first_name}</TableCell>
                    <TableCell align="left">{admin.email}</TableCell>
                    <TableCell
                      className="description-column"
                      width="46%"
                      align="left"
                    >
                      {admin.description?.length > 45 ? (
                        // && showMore === false
                        <span>
                          {
                            
                            admin.description.substring(0, 38)}...
                          <span style={{ color: "blue" }} onClick={toggleMore}>
                            <BootstrapTooltip title={admin.description}>
                              <span>more</span>
                            </BootstrapTooltip>
                          </span>
                        </span>
                      ) : (
                        // : client.description.length > 50 &&
                        //   showMore === true ? (
                        //   <span>
                        //     {client.description}{" "}
                        //     <span
                        //       style={{ color: "blue" }}
                        //       onClick={toggleMore}
                        //     >
                        //       {" "}
                        //       less{" "}
                        //     </span>
                        //   </span>
                        // )
                        admin.description?.length,
                        admin.description
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <SwitchKey
                        eachUser={admin}
                        admin={admin}
                        sendingActivityStatus={sendingActivityStatus}
                        status={admin.is_active}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
      {/* <PasswordCanvas placement={"end"} name={"click"} /> */}
      <div id="randomDiv"></div>
    </Container>
  );
}
