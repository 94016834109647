import React from "react";
import { Routes, Route, Navigate} from "react-router-dom";
import Footer from "../components/footer/footer";
import { useState, useEffect } from "react";
import { getTokenLocal, isLoggedInSuperUser } from "../Utils/Common";
import { useNavigate } from "react-router-dom";
import ContactUs from "../components/contact/ContactUs";
import AdminManager from "../views/super-user/AdminManager";
import SuperUserNavbar from "../components/navbar/SuperUserNavbar";
import SuperUserChangePassword from "../components/super-user/settings/ChangePassword";
import SuperUserSettings from "../views/super-user/Settings";

const SuperUserRoutes = (props) => {
  const navigate = useNavigate();
  const [isContact, setIsContact] = useState(false)

  const ifUserLoggedInSuperUser = () => {
    if (getTokenLocal() && isLoggedInSuperUser()) {
      navigate("/superuser/adminmanager");
    } else {
      // flushLocalstorage()
      navigate("/login");
    }
  };
  useEffect(() => {
    ifUserLoggedInSuperUser();
  }, []);

  return (
    <>
      <div className="center_keeping_conatiner">
       
            <SuperUserNavbar />
              {isContact ? <ContactUs isContact={isContact} setIsContact={setIsContact} /> :
              <Routes>
                <Route path="adminmanager" element={<AdminManager />} />
                <Route path="settings" element={<SuperUserSettings/>} />
                
              </Routes>}
            <Footer isContact={isContact} setIsContact={setIsContact} />
        </div>
      {/* <Footer /> */}
    </>
  );
};

export default SuperUserRoutes;
