import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OffCanvasLogs from "../../components/client/canvas/CanvasForLogs";
import AddData from "../../components/client/setting/AddData";
import DisplayCrop from "../../components/client/setting/DisplayCropData";
import Loader from "../../components/Loader/Loader";
import ActivityLogs from "../../components/Logs/ActivityLogs";
import HTTPService from "../../Services/HTTPService";
import { GetErrorHandlingRoute, GetErrorKey } from "../../Utils/Common";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import CustomToast from "../../Utils/Toast/Toast";
import "./logs.css";

const Settings = () => {
  //     const [activityLogsData, setActivityLogsData] = React.useState([]);
  const [loader, setLoader] = useState(false);
  const [showDataDownload, setShowDataDownload] = useState(true);
  const [showHomePageSetting, setShowHomePageSetting] = useState(false)
  const [showAdminProfile, setShowAdminProfile] = useState(false)
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [resetInput, setResetInput] = useState(0);
  const [newPasswordError, setNewPasswordError] = useState("");
  const [updatedData, setUpdatedData] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [cropName, setCropName] = useState([]);
  const [sessionName, setSessionName] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [clientCountry, setClientCountry] = useState([])
  const [cropNameErrorMessage, setCropNameErrorMessage] = useState("")
  const [homePageContent, setHomePageContent] = useState({})

  //   const [show, setShow] = useState(false);
  //     const [canvasData, setCanvasData] = useState();

  //     const handleClose = () => setShow(false);
  //     const handleShow = () => setShow(true);
  // console.log("show",show)
  let navigate = useNavigate();

  const getCropName = () => {
    let url = UrlConstants.base_url + UrlConstants.crop_name;
    setLoader(true);
    HTTPService("GET", url, false, false, true, false)
      .then((res) => {
        // setLoader(false);
        console.log("res in get all crop data", res);
        if (res.status === 200) {
          setCropName(res.data.crop_name);
        }
      })
      .catch((err) => {
        console.log("err", err);
        // setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      });
  };

  const getUpdatedCropData = () => {
    let url = UrlConstants.base_url + "main/activity_logs_list/";
    setLoader(true);
    HTTPService("GET", url, false, false, true, false)
      .then((res) => {
        setLoader(false);
        console.log("res in get all crop data", res);
        if (res.status === 200) {
          setUpdatedData(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      });
  };

  const handleAddData = (payload) => {
    let url = UrlConstants.base_url + "main/add_crop_data/";
    setLoader(true);
    HTTPService("POST", url, payload, false, true, false)
      .then((res) => {
        setLoader(false);
        console.log("res in add crop data", res);
        if (res.status === 200) {
          setToastMessage("Data added successfully");
          setShowToast(true);
          getUpdatedCropData();
          getAllDataAvailableToDownload()
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      });
  };

  const sendingCropDataActivityStatus = (status, crop) => {
    let url =
      UrlConstants.base_url + "main/crop_data/set_crop_data_status/";
    let payload = {
      id: crop.id,
      is_active: status,
    };
    setLoader(true);
    HTTPService("PUT", url, payload, false, true, "")
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          setUpdatedData([...res.data]);
          setReRender(!reRender);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      });
  };

  //       useEffect(() => {
  //         getAllLogs();
  //         }, []);

  const toggleToast = () => setShowToast(false);

  const handlePasswordSubmit = (password) => {
    setLoader(true);
    let url = UrlConstants.base_url + "users/change_password/";
    let data = {
      email_id: JSON.parse(localStorage.getItem("email_id")),
      new_password: password,
      reset: false,
    };
    HTTPService("POST", url, data, false, true)
      .then((res) => {
        setLoader(false);
        console.log(res);
        if (res.status === 201) {
          setToastMessage("Password is updated successfully.");
          setShowToast(true);
          setResetInput(resetInput + 1);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);

        let returnValues = GetErrorKey(err, Object.keys(data));
        let errorKeys = returnValues[0];
        let errorMessages = returnValues[1];

        if (errorKeys.length > 0) {
          for (var i = 0; i < errorKeys.length; i++) {
            switch (errorKeys[i]) {
              case "new_password":
                setNewPasswordError(err.response.data[errorKeys[i]]);
                console.log("new_password", errorMessages[i]);

                break;
              //     case "description":
              //       setClientDescriptionErrorMessage(errorMessages[i]);
              //       break;
              //     case "country":
              //       setClientCountryErrorMessage(errorMessages[i]);
              //       break;

              default:
                //   history.push(GetErrorHandlingRoute(e));
                console.log("err in default");
                navigate(GetErrorHandlingRoute(err));
                break;
            }
          }
          setLoader(false);
        } else {
          // setToastMessage("Oops, something went wrong!");
          // setShowErrorToast(true);
          setLoader(false);
          navigate(GetErrorHandlingRoute(err));
        }
      });
  };

  const getAllSession = () => {
    let url = UrlConstants.base_url + "main/seasons/";
    // setLoader(true)
    HTTPService("GET", url, false, false, true, false)
      .then((res) => {
        // setLoader(false)
        if (res.status === 200) {
          setSessionName(res.data.season)
        }

      })
      .catch((err) => {
        // setLoader(false)
        navigate(GetErrorHandlingRoute(err))

      })
  }

  const getAllClient = () => {
    let url = UrlConstants.base_url + "clients/client/";
    HTTPService("GET", url, false, false, true, false)
      .then((res) => {
        // console.log("res in get all client", res);
        if (res.status === 200) {
          setAllClient(res.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        // setToastMessage("Oops, something went wrong!");
        // setShowErrorToast(true);
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      });
  };

  const postAllData = (payload) => {
    let url = UrlConstants.base_url + UrlConstants.data_download_post_end_point;
    setLoader(true)
    HTTPService("POST", url, payload, false, true, false)
      .then((res) => {
        if (res.status === 201) {
          console.log("add data res", res)
          setLoader(false)
          setToastMessage("Data added successfully.");
          setShowToast(true);
          getAllDataAvailableToDownload()
          setResetInput(resetInput + 1);
        }
      })
      .catch((err) => {
        console.log("err", err)
        let returnValues = GetErrorKey(err, Object.keys(payload));
        let errorKeys = returnValues[0];
        let errorMessages = returnValues[1];

        if (errorKeys.length > 0) {
          for (var i = 0; i < errorKeys.length; i++) {
            switch (errorKeys[i]) {
              case "crop_name":
                setCropNameErrorMessage(errorMessages[i]);

                break;
              // case "description":
              //   setClientDescriptionErrorMessage(errorMessages[i]);
              //   break;
              // case "country":
              //   setClientCountryErrorMessage(errorMessages[i]);
              //   break;

              default:

                navigate(GetErrorHandlingRoute(err));
                break;
            }
          }
          setLoader(false);
        } else {
          setLoader(false);
          navigate(GetErrorHandlingRoute(err));
        }
      })
  }

  console.log("CropNameErrorMessage",cropNameErrorMessage)

  const getAllDataAvailableToDownload = () => {
    let url = UrlConstants.base_url + "main/crop_data/";
    setLoader(true)
    HTTPService("GET", url, false, false, true, false)
      .then((res) => {
        console.log("allavailableDataToDownload", res)
        setLoader(false)
        if (res.status === 200) {
          setUpdatedData(res.data)
          console.log("main/crop_data/", res)
        }
      })
      .catch((err) => {
        console.log("err in getting data available to donwnload", err)
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      })
  }

  const getHomePageContent = () =>{
    let url = UrlConstants.base_url + "main/site/content/";
    setLoader(true)
    HTTPService("GET", url, false, false, true, false)
      .then((res) => {
        console.log("getHomePageContent", res)
        setLoader(false)
        if (res.status === 200) {
          setHomePageContent(res.data)
        }
        console.log("main/site/content/", res)
      })
      .catch((err) => {
        console.log("err in getting homepage content", err)
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      })
  }

  const updateHomePageContent = (payload) =>{
    let url = UrlConstants.base_url + "main/site/update_content/";
    setLoader(true)
    HTTPService("PUT", url, payload, false, true, false)
      .then((res) => {
        console.log("getHomePageContent", res)
        setLoader(false)
        if (res.status === 201) {
          setHomePageContent(res.data)
        }
        console.log("main/site/update_content/", res)
      })
      .catch((err) => {
        console.log("err in getting homepage content after update", err)
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      })
  }




  useEffect(() => {
    getHomePageContent()
    getCropName();
    getAllSession()
    getAllClient()
    getAllDataAvailableToDownload()
  }, []);

  return (
    <div>
      {showToast ? (
        <CustomToast text={toastMessage} toggleToast={toggleToast} />
      ) : null}
      <Container className="client-manager-container">
        {loader ? <Loader /> : null}
        <AddData
          // handleAddData={handleAddData}
          showDataDownload={showDataDownload}
          setShowDataDownload={setShowDataDownload}
          newPasswordError={newPasswordError}
          setNewPasswordError={setNewPasswordError}
          handlePasswordSubmit={handlePasswordSubmit}
          resetInput={resetInput}
          cropName={cropName}
          sessionName={sessionName}
          allClient={allClient}
          postAllData={postAllData}
          updatedData={updatedData}
          cropNameErrorMessage={cropNameErrorMessage}
          setCropNameErrorMessage={setCropNameErrorMessage}
          showHomePageSetting={showHomePageSetting}
          setShowHomePageSetting={setShowHomePageSetting}
          showAdminProfile={showAdminProfile}
          setShowAdminProfile={setShowAdminProfile}
          homePageContent={homePageContent}
          updateHomePageContent={updateHomePageContent}
        />
        {showDataDownload ? (
          <DisplayCrop
            sendingCropDataActivityStatus={sendingCropDataActivityStatus}
            updatedData={updatedData}
          />
        ) : null}
      </Container>
    </div>
  );
};

export default Settings;
