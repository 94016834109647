import {
    Nav,
    NavLink,
    NavMenu,
    NavBtn,
    NavBtnLink,
  } from "./NavbarElement";
  import cimmytlogo from "../../Assets/Image/cimmyt.svg";
  import { flushLocalstorage } from "../../Utils/Common";
import { Col, Row } from "react-bootstrap";
import HTTPService from "../../Services/HTTPService";
import { useNavigate } from "react-router-dom";
import UrlConstants from "../../Utils/Constants/UrlConstants";
  export default function UserNavbar() {

    let navigate = useNavigate();
  

    const logout = () => {
      let payload = {
        session: JSON.parse(localStorage.getItem("sessionId"))
      }
      let url = UrlConstants.base_url+"users/logout/";
      HTTPService(
        "POST",
        url,
        payload,
        false,
        true,
        false,
      )
        
        .then((res) => {
          console.log("res in logout", res);
          flushLocalstorage();
          navigate("/login")
        })
        .catch((err) => {
          console.log("err", err);
          flushLocalstorage();
          navigate("/login")
        });
      }

      

    const handleLogout = (e) => {
      e.preventDefault();
      logout();
      // flushLocalstorage();
      //  navigate("/login");
    };
    return (
      <div 
      // style={{position: "relative", zIndex: "1050"}} 
      >
        <Nav>
          <Row>
          <Col xs={2} lg={2}>
              <div>
                <img
                  className="image"
                  // imglogo={require("../../Assets/Image/cimmyt.svg")}
                  src={cimmytlogo}
                  alt="cimmyt"
                  style={{
                    width: "162px",
                    height: "39px",
                    "margin-left": "22px",
                    "margin-top": "9px",
                  }}
                />
              </div>
            </Col>
          {/* Add crop, data, downlode in navbar */}
          <Col xs={8} lg={8} className="routes-container">
              <NavMenu>
                <NavLink to="/user/datadownload" activeStyle>
                  Crop Data Download
                </NavLink>
                <NavLink to="/user/logs" activeStyle>
                  Activity Log
                </NavLink>
                <NavLink to="/user/profile" activeStyle>
                  UserProfile
                </NavLink>
              </NavMenu>
            </Col>
            <Col xs={2} lg={2}>
              <NavBtn onClick={handleLogout}>
                <NavBtnLink to="/login">{"Logout"}</NavBtnLink>
              </NavBtn>
            </Col>
          </Row>
        </Nav>
      </div>
    );
  }