import react, { useState, useEffect } from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "../navbar/NavbarElement";
import cimmytlogo from "../../Assets/Image/cimmyt.svg";
import { Navigate, useNavigate } from "react-router-dom";
import dglogo from "../../Assets/Image/dglogo.svg";
import sessionExpiredImg from "../../Assets/Image/session-expired.svg"
import { Col, Container, Row } from "react-bootstrap";
import Button from "@mui/material/Button";
import "../../Utils/common.css";
// import THEME_COLORS from '../../Constants/ColorConstants'
import { useHistory } from "react-router-dom";
import "./sessionExpired.css";
import Footer from "../footer/footer";
import { flushLocalstorage } from "../../Utils/Common";
import ContactUs from "../contact/ContactUs";

{/* session expired component which contains one image one text one login button using mui */}

const useStyles = {
  btncolor: {color: "green", "border-color": "green", "border-radius": 0},
  marginrowtop: {"margin-top": "30px"},
  marginrowtop50: {"margin-top": "50px"},
  marginrowtop20: {"margin-top": "20px"},
  headingbold:{fontWeight: "bold"},
  headingcolorbold:{fontWeight: "bold",color: "red"}
};

const SessionExpired = () => {
  
  const navigate = useNavigate()
  const [isContact, setIsContact] = useState(false);


  useEffect(() => {
    flushLocalstorage()
  }, []);
  return (
    <div className="center_keeping_conatiner" align="center" >
      <Nav>
        <Row>
        <Col style={{ marginTop: "10px" }} xs={6} sm={6} md={6} lg={6}>
            <img
              className="image"
              // imglogo={require("../../Assets/Image/cimmyt.svg")}
              src={cimmytlogo}
              alt="cimmyt"
              style={{ width: "162px", height: "39px" }}
            />
            <span className="verticalSeparator"></span>
            <img
              className="image"
              src={dglogo}
              alt="digitalgreen"
              style={{ width: "213.37px", height: "27.86px" }}
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}></Col>
          <Col xs={12} sm={12} md={2} lg={2}>
          </Col>
        </Row>
      </Nav>
      <div  >
      {/* <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="session-expired-container">
            <img
              className="image"
              src={" df"}
              alt="session-expired"
              style={{ width: "100%", height: "100%" }}
            />
                      </div>
        </Col>
      </Row> */}
        {/* <Col xs={12} sm={12} md={12} lg={12}>
          <div className="session-expired-text">
            <h4>Session has experied</h4>
            <p>
            Your login session has experied, please sign in again.
            </p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="session-expired-button">
            <NavBtn>
              <NavBtnLink to="/login">{"Login"}</NavBtnLink>
            </NavBtn>
          </div>
        </Col> */}
        {isContact ? <ContactUs isContact={isContact} setIsContact={setIsContact} /> :
        <>
        <Row style={useStyles.marginrowtop50}>
                <Col xs={12} sm={12} md={12} lg={12} >
                    <span className="mainheadingsuccess">
                    Oops!                    </span>
                </Col>
            </Row>
            <Row style={useStyles.marginrowtop}>
                <Col xs={12} sm={12} md={12} lg={12} >
                    <img
                        src={sessionExpiredImg}
                        alt="new"
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} style={useStyles.marginrowtop20}>
                    <span className="secondmainheadingsuccess">
                    Session has expired
                    </span>
                </Col>
            </Row>
            <Row style={useStyles.marginrowtop20}>
                <Col xs={12} sm={12} md={12} lg={12} >
                    <span className="thirdmainheadingsuccess">
                    Your login session has experied, please sign in again.
                    </span>
                </Col>
            </Row>
            <Row style={useStyles.marginrowtop50}>
                <Col xs={12} sm={12} md={12} lg={12} >
                    <Button  onClick={()=>navigate("/login")} variant="contained" className="submitbtn" style={{'text-transform': 'uppercase'}}>
                        <span>Sign In</span>
                    </Button>
                </Col>
            </Row>
            </>
}
            </div>
            <Footer isContact={isContact} setIsContact={setIsContact} />
    </div>
  );
}

export default SessionExpired;

