import React, { useEffect } from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Col, Row } from "react-bootstrap";
import "./Login.css";
import cimmytlogo from "../../Assets/Image/CIMMYTLogologin.svg";
import loginimg from "../../Assets/Image/loginimg.svg";
import dglogo from "../../Assets/Image/dglogo.svg";
import Loader from "../Loader/Loader";
import PasswordStrengthBar from "react-password-strength-bar"
import NiceInputPassword from 'react-nice-input-password';
import { InputLabel } from "@mui/material";
import validator from "validator";
import PasswordCanvas from "../client/canvas/PasswordCanvas";
import TextFieldPassRePass from "../text-field-with-pass-retype-pass/TextFieldPassRePass";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import Success from "../Success/Success";
import { useNavigate, useParams } from "react-router-dom";
import HTTPService from "../../Services/HTTPService";
import { GetErrorHandlingRoute, GetErrorKey, setTokenLocal } from "../../Utils/Common";
// import 'react-nice-input-password/dist/react-nice-input-password.css';

const useStyles = {
  leftimage: { height: "100%", paddingRight: "106px" },
  image1: { width: "286px", height: "70px", "margin-top": "50px", margin: "auto" },
  formStyle: { marginTop: "80px" },
  secondField: { height: "27px", marginTop: "35px" },
  thirdField: { height: "27px", marginTop: "45px" },
  forgotpasswordfield: { width: "100%" },
  buttonstyle: { marginTop: "30px" },
  lastImage: { marginLeft: "0px" },
  cimmylog: { margin: " 50px auto 125px auto" },
  cimmylogforSuccessPass: { margin: " 30px auto 0px auto" },
  cimmytLogoMainDiv: { width: "fit-content", margin: "auto" },
  changePassHeading: { width: "fit-content", margin: "auto" },
  marginBottom30px: { marginBottom: "30px" },
  bottomLogoDiv: { width: "fit-content", margin: "40px auto" },
  successComponent: { marginBottom: "180px" }

}

export default function ChangePassword({ firstTimeLogin, resetLinkFromMail, ...props }) {
  const [password_error_reset, setPassword_error_reset] = useState("")
  const [password_error_enforced, setPassword_error_enforced] = useState("")
  let { userid, token } = useParams()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [retypepassword, setRetypePassword] = useState("");
  const [isloader, setIsLoader] = useState(false)
  const [show, setShow] = useState(false);
  const [passChangeSuccess, setPassChangeSuccess] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [firstTimeLoginFlag, setFirstTimeLogin] = useState(false)
  const [firstTimeLoginEmailId, setFirstTimeEmail] = useState("")

  //reset mail id and password states
  const [userEmailFromResetLink, setUserEmailFromResetLink] = useState("")
  const [passwordFromResetEvent, setPasswordFromResetEvent] = useState("")
  const [retypedPassFromResetEventLink, setRetypePassFromResetEventLink] = useState("")

  //enforced/wantedly mail id and password states
  const [mailIdForEnforced, setMailIdForEnforced] = useState("")
  const [passwordFromEnforceEvent, setpasswordFromEnforceEvent] = useState("")
  const [retypePasswordFromEnforceEvent, setRetypePasswordFromEnforceEvent] = useState("")

  //navigator for routing
  const navigator = useNavigate()

  // const handlepassword = (e) => {
  //   // e.preventDefault();
  //   e.target.value = e.target.value.trim()
  //   console.log(e.target.value)
  //   var password = e.target.value
  //   const valid = validator.isStrongPassword(password);
  //   console.log(valid);
  //   const finalPassword = password.trim()
  //   setPassword(password);
  //   console.log(finalPassword)
  // }

  //Get request as soon as the user clicks on the reset link sent on users mail id to fetch the email id from backend
  function getEmailIdForChangePassword(id, token) {
    setIsLoader(true)
    setTokenLocal(token)
    let payload = { id, token };

    HTTPService(
      "POST",
      UrlConstants.base_url + UrlConstants.validateAndGetMailId,
      payload,
      false,
      false,
      false
    ).then((res) => {
      setIsLoader(false)
      setUserEmailFromResetLink(res.data.email)
    }).catch((e) => {
      setIsLoader(false)
      if (e.response.status == 403) {
        navigator("/reset_link_expired")
      } else {
        navigator(GetErrorHandlingRoute(e));
      }
    })
  }

  //ok event when user wants to reset password
  const handleResetSubmitPassword = (password) => {
    setIsLoader(true)
    console.log("ok", password)
    let urlToHit_reset = UrlConstants.base_url + UrlConstants.changePassEndPoint
    let payload = {
      "email_id": userEmailFromResetLink ? userEmailFromResetLink.trim() : "",
      "new_password": password,
      "reset": true,
      "reset_token": token
    }
    HTTPService(
      "POST",
      urlToHit_reset,
      payload,
      false,
      false,
      true
    ).then(() => {
      setIsLoader(false)
      setPassChangeSuccess(true)
    }).catch((e) => {
      // setIsLoader(false)
      let returnValues = GetErrorKey(e, Object.keys(payload));
      let errorKeys = returnValues[0];
      let errorMessages = returnValues[1];

      if (errorKeys.length > 0) {
        for (var i = 0; i < errorKeys.length; i++) {
          switch (errorKeys[i]) {
            case "new_password":
              setPassword_error_reset(e.response.data.new_password);
              break;

            default:
              //   history.push(GetErrorHandlingRoute(e));
              console.log("err in default");
              navigator(GetErrorHandlingRoute(e));

              break;
          }
        }
        setIsLoader(false);
      } else {
        // setToastMessage("Oops, something went wrong!");
        // setShowErrorToast(true);
        setIsLoader(false);
        navigator(GetErrorHandlingRoute(e));
      }
    })
  }

  //when user is enforced to the password
  const handleEnforceChangePassword = (password) => {
    setIsLoader(true)
    console.log("enforcd")
    let urlToHit_reset = UrlConstants.base_url + UrlConstants.changePassEndPoint

    let payload = {
      "email_id": mailIdForEnforced ? mailIdForEnforced.trim() : "",
      "new_password": password,
      "reset": false,
      "reset_token": ""
    }
    HTTPService(
      "POST",
      urlToHit_reset,
      payload,
      false,
      true,
      JSON.parse(localStorage.getItem("temp_token"))
    ).then((res) => {
      setIsLoader(false)
      setPassChangeSuccess(true)
    }).catch((e) => {

      let returnValues = GetErrorKey(e, Object.keys(payload));
      let errorKeys = returnValues[0];
      let errorMessages = returnValues[1];
      if (errorKeys.length > 0) {
        for (var i = 0; i < errorKeys.length; i++) {
          switch (errorKeys[i]) {
            case "new_password":
              setPassword_error_enforced(e.response.data.new_password);
              break;

            default:
              //   history.push(GetErrorHandlingRoute(e));
              console.log("err in default");
              navigator(GetErrorHandlingRoute(e));

              break;
          }
        }
        setIsLoader(false);
      } else {
        // setToastMessage("Oops, something went wrong!");
        // setShowErrorToast(true);
        setIsLoader(false);
        navigator(GetErrorHandlingRoute(e));
      }

    })
  }
  var firstLoginEmail


  useEffect(() => {
    //this email id is set while user is enforced to move from login to change pass path and this is used to make a submit call.
    firstLoginEmail = JSON.parse(localStorage.getItem("cimmyt_emailId"))
    if (firstLoginEmail) {
      setMailIdForEnforced(firstLoginEmail)
      // setFirstTimeEmail(firstLoginEmail)
      // setFirstTimeLogin(firstTimeLogin)
    }
    if (props.resetChangePassword && userid && token) {
      getEmailIdForChangePassword(userid, token)
    }
    console.log(props.enforceChangePassword, props.resetChangePassword)

  }, [])
  return (
    (
      <>

        {isloader ? <Loader /> : <>

          {/* When user is successfully changed the password, passChangeSuccess state will be made true and component will be redered */}
          {passChangeSuccess ? <Row>
            <Col style={{ margin: "auto" }} xs={12} sm={12} md={6} lg={6}>
              <Row>

                <div style={useStyles.cimmytLogoMainDiv} >
                  <img
                    src={cimmytlogo}
                    alt="login"
                    style={useStyles.cimmylogforSuccessPass} />
                </div>
              </Row>
              <Row style={useStyles.successComponent}>  <Success okevent={() => navigator("/login")} btntext="Login" imageText="Success!" msg="Your password is updated sucessfully, please try to login again." heading="Password updated" /></Row>
              <Row>
                <div style={useStyles.bottomLogoDiv}>
                  <img
                    src={dglogo}
                    alt="login"
                    style={useStyles.dglogo} />
                </div>
              </Row>
            </Col>

          </Row> : ""}
          {!passChangeSuccess ? <Row>

            <Col xs={12} sm={12} md={6} lg={6}>
              <div style={{}}>
                <img
                  src={loginimg}
                  alt="login"
                  style={useStyles.leftimage} />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} >
              <Row>

                <div style={useStyles.cimmytLogoMainDiv} >
                  <img
                    src={cimmytlogo}
                    alt="login"
                    style={useStyles.cimmylog} />
                </div>
              </Row>
              <Row>
                <div style={useStyles.changePassHeading}>
                  <div style={useStyles.marginBottom30px}>
                    <span>Change Password</span>
                  </div>
                  {props.resetChangePassword && <TextFieldPassRePass setPassword_error_reset={setPassword_error_reset} err={password_error_reset} email={userEmailFromResetLink} okEvent={(pass) => handleResetSubmitPassword(pass)} />}
                  {props.enforceChangePassword && <TextFieldPassRePass setPassword_error_reset={setPassword_error_enforced} err={password_error_enforced} email={JSON.parse(localStorage.getItem("cimmyt_emailId"))} okEvent={(pass) => handleEnforceChangePassword(pass)} />}
                  {/* <TextFieldPassRePass setIsLoader={setIsLoader} setPassChangeSuccess={setPassChangeSuccess} userid={userid} token={token} resetLinkFromMail={resetLinkFromMail} firstTimeLoginEmailId={firstTimeLoginEmailId} firstTimeLogin={firstTimeLoginFlag} disabledEmail={disabledEmail} url={UrlConstants.base_url + UrlConstants.changePassEndPoint} /> */}
                </div>
              </Row>
              <Row>
                <div style={useStyles.bottomLogoDiv}>
                  <img
                    src={dglogo}
                    alt="login"
                    style={useStyles.dglogo} />
                </div>
              </Row>

            </Col>
          </Row> : ""}
          {/* <Col style={{ margin: "auto" }} xs={12} sm={12} md={6} lg={6}>
            <img
              style={useStyles.image1}
              src={cimmytlogo}
              alt="cimmyt"

            /> */}

          {/* <div noValidate autoComplete="off" > */}
          {/* <Row style={useStyles.formStyle} >
              <Col xs={12} sm={12} md={6} lg={6} >
                <TextField
                  required
                  id="standard-basic"
                  label="User name"
                  type="email"
                  variant="standard"
                  className="username"
                value={usernameoremail}
                />
              </Col>
            </Row> */}


          {/* </Row> */}

          {/* <PasswordCanvas handleClose={handleClose} handleShow={handleShow} show={show} placement={"end"} topImage={true} bottomImage={true} topHeadingTitle={"Change Password"} headingWithForm="Change Password" topHeading={false} /> */}
        </>
        }
      </>

    )
  )
}