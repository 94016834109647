import { Col, Collapse, Row } from "react-bootstrap";
import contactusImageleft from "../../Assets/Image/contactUsFirstImage.svg";
import contactusImageRight from "../../Assets/Image/contactUsSecondimage.svg"
import backImage from "../../Assets/Image/Vector.svg"
import { getTokenLocal, isLoggedInUserAdmin, isLoggedInUserNormal } from "../../Utils/Common";
import Home from "../../views/home/Home";
import Footer from "../footer/footer";
import HomeNavBar from "../home/HomeNavBar";
import AdminNavbar from "../navbar/AdminNavbar";
import UserNavbar from "../navbar/UserNavbar";


 const useStyles = {
    backButton : {marginTop: "80px", marginLeft: "94px", cursor:"pointer", textAlign: "left"},
    backButtonImage: {width: '16px', height: '16px'},
    backButtontop: {fontFamily:"Avenir", fontWeight:"400", fontSize:"14px", color: "#2351F5"},
    contactBackground: {background:"#1E1E1E"},
    contactFirtImage: {width: "616px", height: "470px", boxSizing: "border-box", background: "#FFFFFF", border: "1px solid #BCEAB5", borderRadius: "10px", marginLeft: "94px", marginTop: "50px", },
    firstDescription: {paddingTop: "50px", textAlign:"center"},
    descriptionleft: {width: "298px", height:"66px",margin:"auto",  fontFamily:"Avenir", fontWeight: "400", fontSize:"24px", alignItems:"center"},
    leftMail: {paddingTop: "20px", color: "#2351F5", marginLeft: "200px", fontSize:"24px", fontWeight:"400"},
    contactSecondImage: {width: "616px", height: "470px", boxSizing: "border-box", background: "#FFFFFF", border: "1px solid #BCEAB5", borderRadius: "10px", marginLeft: "14px", marginTop: "50px", },
    secondDescription: {paddingTop: "50px", textAlign:"center"},
    descriptionright: {width: "298px", height:"66px",  fontFamily:"Avenir", fontWeight: "400", fontSize:"24px", lineHeight:"32.78px"},
    rightmail: {paddingTop: "50px", color: "#2351F5", marginLeft: "200px", fontSize:"24px", fontWeight:"400"}
 }


export default function ContactUs(props) {
 
    return (
    <>
        
        <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
            <div class="link" 
            style={useStyles.backButton}
            onClick={()=>props.setIsContact(false)}
            >
            <img
                src={backImage}
                alt="new"
                style={useStyles.backButtonImage}
            />&nbsp;&nbsp;&nbsp;
            <span style={useStyles.backButtontop}>Back</span>
            </div>
            </Col>
        </Row>
    
        <Row >
            <div style={useStyles.contactBackground}></div>
            <Col xs={12} sm={12} md={6} lg={6}>
            <div style={useStyles.contactFirtImage}>
            <img
            style={{marginLeft: "166px"}}
            src={contactusImageleft}
            alt="contactusimage"
            />
            <Col>
            <div style={useStyles.firstDescription}>
            <div style={useStyles.descriptionleft}>
                For all issues related to the administration of this site</div>
            </div>
            <div style={useStyles.leftMail}>
                <div>
                    <a href="mailto: admin.abcd.org">admin.abcd.org</a>
                </div>
            </div>
            </Col>
            </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
            <div style={useStyles.contactSecondImage}>
            <img
            style={{marginLeft: "166px"}}
            src={contactusImageRight}
            alt="contactusimage"
            />
            <Col>
                <div style={useStyles.secondDescription}>
                <span style={useStyles.descriptionright}>
                To reach out to CIMMYT contact</span>
                </div>
                <div style={useStyles.rightmail}>
                    <span>
                    <a href="mailto: contact@cimmyt.com">contact@cimmyt.com</a>
                    </span>
                </div>
            </Col>
            </div>
            </Col>
        </Row>
    </>
    )
}
