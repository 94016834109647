import React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button'
import { Col, Row } from "react-bootstrap";
import "./Login.css"
import cimmytlogo from "../../Assets/Image/CIMMYTLogologin.svg"
import loginimg from "../../Assets/Image/loginimg.svg"
import dglogo from "../../Assets/Image/dglogo.svg"
import HTTPService from "../../Services/HTTPService";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import Loader from "../Loader/Loader";
import {
    setRoleLocal,
    setTokenLocal,
    getTokenLocal,
    setUserId,
    getUserLocal,
    setUserMapId,
    setOrgId,
    isLoggedInUserAdmin,
    isLoggedInUserNormal,
    getRoleLocal,
    getUserMapId,
    GetErrorKey,
    validateInputField,
    isNormalRoute,
    setUserIdLocal,
    GetErrorHandlingRoute,
    setSesssionIdLocal,
    validate,
    NO_SPACE_REGEX,
} from "../../Utils/Common";
import RegexConstants from "../../Utils/Constants/RegexConstants";
import LocalStorageConstants from "../../Utils/Constants/LocalStorageConstants";
import AdminRoutes from "../../layout/Admin";
import CustomToast from "../../Utils/Toast/Toast";
import PasswordCanvas from "../client/canvas/PasswordCanvas";
import Success from "../Success/Success";
import ChangePassword from "./ChangePassword";
import { createTheme } from "@mui/system";


const theme = createTheme({

    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: { color: "red" },
            },
        },
    },

})
const useStyles = {
    image1: { width: "286px", height: "70px", "margin-top": "50px", "margin-left": "50px" },
    formStyle: { height: "6%", marginTop: "198px" },
    formStyleMailID: { height: "6%", marginTop: "50px" },
    secondField: { height: "27px", marginTop: "35px" },
    forgotpasswordfield: { marginTop: "54px", width: "10%" },
    buttonstyle: { marginTop: "56px" },
    lastImage: { marginLeft: "93px" },
    cimmytLogoMainDiv: { margin: "30px auto 0px auto", textAlign: "center" },
    bottomLogoDiv: { margin: "200px auto 30px auto", textAlign: "center" }


}


export default function ForgotPassword(props) {

    const [emailIdForMail, setEmailIdForMail] = useState("")
    const [forgotMailIdError, setForgotMailIdError] = useState("")
    const [showToast, setShowToast] = useState(false)
    const navigate = useNavigate();
    const [isloader, setIsLoader] = useState(false)
    const [resetPasswordLinkSendSuccess, setresetPasswordLinkSendSuccess] = useState(false)
    const [valid, setValid] = useState(false)

    const toggleToast = () => {
        setShowToast(!showToast)
    }
    const [isOnBoard, setIsOnBoard] = useState(false)
    const landingPageUserCredentialsCheckFromLocal = () => {
        if (isLoggedInUserAdmin()) {

            navigate("/admin/clientmanager")
        } else if (isLoggedInUserNormal()) {

            navigate("/user/datadownload")
        }
    }
    useEffect(() => {
        landingPageUserCredentialsCheckFromLocal()
    }, [])



    const submitForgotPassword = (email) => {

        let payload = {
            email: email
        }
        let url = UrlConstants.base_url + UrlConstants.reset_password_mail_link
        setIsLoader(true);
        HTTPService(
            "POST",
            url,
            payload,
            false,
            false,
            false
        ).then((res) => {
            setIsLoader(false)
            // console.log(res)
            // setResetMailLinkMsg(res.data.message)
            setresetPasswordLinkSendSuccess(true)

        }).catch((err) => {

            let returnValues = GetErrorKey(err, Object.keys(payload));
            let errorKeys = returnValues[0];
            let errorMessages = returnValues[1];

            // console.log(returnValues)
            if (errorKeys.length > 0) {
                for (var i = 0; i < errorKeys.length; i++) {
                    switch (errorKeys[i]) {
                        case "email":
                            setForgotMailIdError(err.response.data.email);
                            break;
                        default:
                            //   history.push(GetErrorHandlingRoute(e));
                            // console.log("err in default");
                            navigate(GetErrorHandlingRoute(err));
                            break;
                    }
                }
                setIsLoader(false);
            }
            else {
                // setToastMessage("Oops, something went wrong!");
                // setShowErrorToast(true);
                setIsLoader(false);
                navigate(GetErrorHandlingRoute(err));
            }
        })
    }

    const handleForgotMailId = (e) => {

        e.target.value = e.target.value.trim()
        const valid = validator.isEmail(e.target.value);
        // console.log(valid);
        if (validate(e.target.value, NO_SPACE_REGEX)) {
            setEmailIdForMail(e.target.value);
        }
        setValid(valid)
    }

    const mailSentFunc = () => {
        setresetPasswordLinkSendSuccess(false)
        navigate("/login")

    }

    useEffect(() => {

    }, [])

    return (
        <>

            {
                showToast ? <CustomToast error={true} text={"Something went wrong"} toggleToast={toggleToast} /> : null
            }
            {resetPasswordLinkSendSuccess ?
                <Row>
                    <Col style={{ margin: "auto" }} xs={12} sm={12} md={6} lg={6}>
                        <Row>

                            <div style={useStyles.cimmytLogoMainDiv} >
                                <img
                                    src={cimmytlogo}
                                    alt="login"
                                    style={useStyles.cimmylogforSuccessPass} />
                            </div>
                        </Row>
                        <Row style={useStyles.successComponent}>  <Success okevent={() => mailSentFunc()} btntext="Ok" imageText="Reset link sent to your mail id" msg="Please go to your mail and hit the link." heading="Password update" /></Row>
                        <Row>
                            <div style={useStyles.bottomLogoDiv}>
                                <img
                                    src={dglogo}
                                    alt="login"
                                    style={useStyles.dglogo} />
                            </div>
                        </Row>
                    </Col>

                </Row>
                :
                isloader ? <Loader /> : <div className="center_keeping_conatiner">
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <div>
                                <img
                                    src={loginimg}
                                    alt="login" />
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6}>
                            <img
                                style={useStyles.image1}
                                src={cimmytlogo}
                                alt="cimmyt"
                            />

                            <div style={{ marginTop: "100px" }}>
                                <div> Forgot Password?</div>
                                <div> Please enter your mail id</div>
                            </div>
                            <Row style={useStyles.formStyleMailID} >
                                <Col xs={12} sm={12} md={12} lg={12} >
                                    <TextField
                                        required
                                        id="standard-basic"
                                        label="Mail Id"
                                        type="email"
                                        // inputProps={{inputMode: "email", pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"}}
                                        variant="standard"
                                        className="username"
                                        value={emailIdForMail}
                                        onChange={(e) => {
                                            handleForgotMailId(e)
                                        }
                                        }
                                        onKeyDown={(e) => { if (e.key == ' ') { e.preventDefault() } }}
                                        error={forgotMailIdError ? true : false}
                                        helperText={forgotMailIdError ? forgotMailIdError : ""}
                                    />
                                </Col>
                            </Row>

                            <Row>
                            </Row>

                            <Row>
                                <div style={useStyles.buttonstyle} >
                                    {validator.isEmail(emailIdForMail) ? (
                                        <Button variant="contained"
                                            onClick={() => submitForgotPassword(emailIdForMail)}
                                            type="submit"
                                            className="submitbtn">Submit</Button>
                                    ) : (<Button variant="outlined" disabled
                                        type="submit"
                                        className="submitbtn disabled-submit-btn">Submit</Button>)}

                                </div>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "219px", }} >
                                    <img
                                        style={useStyles.lastImage}
                                        className="image"
                                        src={dglogo}
                                        alt="digitalgreen"
                                    />
                                </Col>
                            </Row>

                            {/* </div> */}


                        </Col>

                    </Row>
                </div>
            }

        </>

    )
}