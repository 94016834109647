import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getData, getCode } from "country-list";
import AddClient from "../../components/client/AddClient";
import DisplayClient from "../../components/client/DisplayClient";
import "./clientManager.css";
import HTTPService from "../../Services/HTTPService";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import { GetErrorHandlingRoute, GetErrorKey } from "../../Utils/Common";
import Loader from "../../components/Loader/Loader";
import CustomToast from "../../Utils/Toast/Toast";
import ContactUs from "../../components/contact/ContactUs";
import { Navigate, useNavigate } from "react-router-dom";

const ClientManager = (props) => {
  const [allClient, setAllClient] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [clientNameErrorMessage, setClientNameErrorMessage] = useState(null);
  const [reRender, setRender] = useState(false)
  const [clientCountryErrorMessage, setClientCountryErrorMessage] =
    useState(null);
  const [clientDescriptionErrorMessage, setClientDescriptionErrorMessage] =
    useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [clearState, setClearState] = useState(0);
  // state for loder
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate()

  let bodyFormData = new FormData();

  const toggleToast = () => {
    setShowToast(!showToast);
  };

  const toggleErrorToast = () => {
    setShowErrorToast(!showErrorToast);
  };

  const checkJwtAuth = () => { };

  const resetErrorMessage = () =>{
    setClientNameErrorMessage(null);
    setClientCountryErrorMessage(null);
    setClientDescriptionErrorMessage(null);
  }

  // Make a post request to the server to add the client

  const addClient = (data, e) => {
    
    setClientNameErrorMessage(null);
    setClientCountryErrorMessage(null);
    setClientDescriptionErrorMessage(null);

    let countries = getData();
    let countryObj = data.country;

    if (data.name && data.country) {
      
      for (let i of countries) {
        if (i.name === data.country) {
          countryObj = i;
          break;
        }
      }

      let payloadData = {
        client_name: data.name,
        country: countryObj,
        description: data.description,
      };
      let url = UrlConstants.base_url + "clients/client/";
      setLoader(true);

      HTTPService("POST", url, payloadData, false, true, false)
        .then((res) => {
          
          //   on successeful responce display toast
          if (res.status === 201) {
            setToastMessage("The client is added successfully.");
            setShowErrorToast(false);
            toggleToast();
            getAllClient();
            setClearState(clearState + 1);
            setLoader(false);
            return;
          }

          return;
        })
        .catch((err) => {
          console.log("err", err);
          let returnValues = GetErrorKey(err, Object.keys(payloadData));
          let errorKeys = returnValues[0];
          let errorMessages = returnValues[1];

          if (errorKeys.length > 0) {
            for (var i = 0; i < errorKeys.length; i++) {
              switch (errorKeys[i]) {
                case "client_name":
                  setClientNameErrorMessage(errorMessages[i]);

                  break;
                case "description":
                  setClientDescriptionErrorMessage(errorMessages[i]);
                  break;
                case "country":
                  setClientCountryErrorMessage(errorMessages[i]);
                  break;

                default:
                  
                  navigate(GetErrorHandlingRoute(err));
                  break;
              }
            }
            setLoader(false);
          } else {
            setLoader(false);
            navigate(GetErrorHandlingRoute(err));
          }
          //   print all error message
        });
    }
  };

  //   create a funtion to get all client
  const getAllClient = () => {
    let url = UrlConstants.base_url + "clients/client/";
    setLoader(true);
    HTTPService("GET", url, false, false, true, false)
      .then((res) => {
        // console.log("res in get all client", res);
        if (res.status === 200) {
          setAllClient(res.data);
          setClearState(true);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        // setToastMessage("Oops, something went wrong!");
        // setShowErrorToast(true);
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      });
  };
  const updateAllClientWthLatestStatus = (newClient) => {
    let newListForAllClient = allClient.map((client) => {
      if (client.id == newClient.id) {
        client = newClient
      }
      return client
    })
    // console.log(newListForAllClient)
    setAllClient([...newListForAllClient])
  }


  const sendingActivityStatus = (status, client) => {
    let url = UrlConstants.base_url + UrlConstants.client_status_toggle_end_point;
    let payload = {
      id: client.id,
      "is_active": status
    }
    setLoader(true);
    HTTPService("PUT", url, payload, false, true, "")
      .then((res) => {
        // console.log("res in get all client", res);
        setLoader(false)
        if (res.status === 201) {
          setAllClient([...res.data]);
          setRender(!reRender)
          // setToastMessage("User status changed successfully.");
          // setShowErrorToast(false);
          // updateAllClientWthLatestStatus(res.data)
          // toggleToast();
          // setShowToast(true);
          // return true
        }
      })
      .catch((err) => {
        console.log("err", err);
        // setToastMessage("Oops, something went wrong!");
        // setShowErrorToast(true);
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));
      });
  }
  //   on page load call getAllClient funtion
  useEffect(() => {
    getAllClient();
    setClearState(0);
  }, []);

  return (
    <>
      {showToast ? (
        <CustomToast
          toggleToast={toggleToast}
          text={toastMessage}
          success={true}
        />
      ) : showErrorToast ? (
        <CustomToast
          toggleToast={toggleErrorToast}
          text={toastMessage}
          error={true}
        />
      ) : null}
      {loader ? <Loader /> : null}
      {/* {props.isContact ? <ContactUs setIsContact={props.setIsContact}/> : */}
      <Container className="client-manager-container">
        <Row xs={12} sm={12} md={12} lg={12}>
          <Col>
            <AddClient
              clearState={clearState}
              addClient={addClient}
              clientNameErrorMessage={clientNameErrorMessage}
              clientCountryErrorMessage={clientCountryErrorMessage}
              clientDescriptionErrorMessage={clientDescriptionErrorMessage}
              resetErrorMessage={resetErrorMessage}
            />
            <DisplayClient sendingActivityStatus={sendingActivityStatus} allClient={allClient} />
          </Col>
        </Row>
      </Container>
      {/* } */}
    </>
  );

};

export default ClientManager;
