import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Container, Row, Col } from "react-bootstrap";
import { getData, getCode } from "country-list";
import "../../../Utils/common.css";
import "./addUser.css";
import { descriptionRegex, nameRegex, validate, NO_SPACE_REGEX, nameField } from "../../../Utils/Common";
import HTTPService from "../../../Services/HTTPService";
import validator from "validator";
import RegexConstants from "../../../Utils/Constants/RegexConstants";

const AddUser = (props) => {
  console.log("AddUser props");
  // Create a form which accept name country and discription

  const [name, setName] = useState("");
  const [country, setCountry] = useState([]);
  const [description, setDescription] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [clientList, setClientList] = useState([]);
  const [client, setClient] = useState({});
  const [validEmail, setValidEmail] = useState(false);
  const [allClientName, setAllClientName] = useState([])
  const [countryList, setCountryList] = useState([])


  // const [showToast, setShowToast] = useState(false);

  const countries = getData();

  let submitBtnClassName = "button disabled-submit-btn";

  if (name && country && client && validEmail) {
    submitBtnClassName = "button submit-btn";
  }

  const handleSubmit = (e) => {

    let data = {
      name: name,
      country: country,
      description: description,
      email: userEmail,
      client: client.id,
    };
    console.log("data in submit", data);
    props.addUser(data, e);
  };

  const handleCancel = () => {
    props.resetErrorMessage()
    setName("");
    setCountry("");
    setDescription("");
    setUserEmail("");
    setClient("");
  };

  const handleName = (e) => {
    props.resetErrorMessage()
    // if (validateName(e.target.value)) {
    //   setName(e.target.value);
    // }
    let valueArray = e.target.value.split(" ");
    let validText = true;
    for(let i in valueArray){
      if(valueArray[i-1]===""){
        validText = false;
        break;
      }
    }
    console.log(validText)
    if (validate(e.target.value, nameField) && (validText) ) {
      setName(e.target.value);
    }
}
  const handleEmail = (e) => {
    props.resetErrorMessage()
    e.target.value = e.target.value.trim()
    console.log(e.target.value)
    const valid = validator.isEmail(e.target.value)
    console.log(valid)
    if(validate(e.target.value, NO_SPACE_REGEX)){
      setUserEmail(e.target.value);}
    setValidEmail(valid);

  };
  const handleDes = (e) => {
    props.resetErrorMessage()
//     let valueArray = e.target.value.split(" ");
//     let validText = true;
//     for(let i in valueArray){
//       if(valueArray[i-1]===""){
//         validText = false;
//         break;
//       }
//     }
//     console.log(validText)
// if(validText){
//   setDescription(e.target.value)

// }
    let twoSpaceRegex = RegexConstants.TWO_SPACE_REGEX;
    if(e.target.value[0]!==" "){
      setDescription(e.target.value.replace(twoSpaceRegex," "))
    }
    
  }

  function onClickOfEnter() {
    if (window.event.keyCode == 13) {
        // console.log("ENTER PRESSED");
        window.event.preventDefault();
        window.event.stopPropagation();
        return false;
    }
}

const removeDuplicates = (arr) =>{
  let dict = {}
  console.log("obj",arr)
  let fillteredArr = []
  for(let i = 0; i< arr.length; i++){
    if(!dict[arr[i].client_name]){
      fillteredArr.push(arr[i]);
      dict[arr[i].client_name] = 1;
    }
  }
  return fillteredArr
}

  

  useEffect(() => {
    // set all state empty
    handleCancel()
    // get client list
    setAllClientName(removeDuplicates(props.client))
    setClientList(props.client);


  }, [props.clearState]);

  const setCountryListOnChange = (e) => {
    setClient(e.target.value)
    let clientCountry = clientList.filter((client, index) => {
      console.log(client.client_name, e.target.value.name)
      return client.client_name === e.target.value.client_name
    })
    setCountry("")
    setCountryList(clientCountry);
    console.log('clientCountry', clientCountry)


  }

  console.log("clientList", clientList, countryList);


  console.log("props", props);

  return (
    <>
      <Container className="main-container">
        
        <Row xs={12} lg={12} md={12} sm={12}>
          <Typography className="titleText" variant="h4">
          Add New User
          </Typography>
        </Row>
        <form>
          <Row container spacing={2}>
            <Col style={{paddingRight: "20px"}} item xs={12} sm={6}>
              <TextField
                onChange={handleName}
                value={name}
                variant="standard"
                id="name"
                name="name"
                label="Name"
                fullWidth
                autoComplete="given-name"
                error={props.userNameErrorMessage ? true : false}
                helperText={props.userNameErrorMessage}
              />
            </Col>
            <Col style={{paddingLeft: "10px", paddingRight: "25px"}} item xs={12} sm={6}>
            <TextField

                onChange={handleEmail}
                onKeyDown={(e) => { if (e.key == ' ') { e.preventDefault() } }}
                value={userEmail}
                variant="standard"
                id="name"
                name="Email"
                label="User ID (mail Id)"
                fullWidth
                autoComplete="given-name"
                error={props.userEmailErrorMessage ? true : false}
                helperText={props.userEmailErrorMessage}
              />
            </Col>

            {/* Create two input box which accept email and client name  */}

            <Col className="description" style={{paddingRight: "10px"}} item xs={12} sm={6}>
            <FormControl variant="standard" className="country-input">
                <InputLabel
                  className="require-input-label"
                  id="demo-simple-select-required-label"
                >
                  Client Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required-label"
                  value={client}
                  onChange={(e) => setCountryListOnChange(e)}
                  label="Client Name *"
                >
                  {allClientName?.map((client, index) => (
                    
                    <MenuItem key={index} value={client}>
                      {client.client_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col className="description" style={{paddingLeft: "10px", paddingRight: "18px"}} item xs={12} sm={6}>
              <FormControl variant="standard" className="country-input">
                <InputLabel
                  className="require-input-label"
                  id="demo-simple-select-required-label"
                >
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required-label"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  label="Country *"
                >
                  {countryList?.map((client, index) => (
                    <MenuItem key={index} value={client.country}>
                      {client?.country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>

            <Row className="description" item xs={12}>
              <Col item xs={12}>
                <TextField
                  onChange={handleDes}
                  onKeyDown={onClickOfEnter}
                  value={description}
                  multiline
                  variant="standard"
                  id="description"
                  name="description"
                  label="Description"
                  fullWidth
                  autoComplete="shipping address-line1"
                  error={props.clientDescriptionErrorMessage ? true : false}
                  helperText={props.clientDescriptionErrorMessage}
                  inputProps={{ maxLength: 250 }}
                />
              </Col>
            </Row>

            <Row spacing={2}>
              <Col className="btn-container" item xs={12} sm={12}>
                <Button
                  className={submitBtnClassName}
                  variant="contained"
                  type="button"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={handleSubmit}
                  disabled={(name && country && client && userEmail && validEmail) ? false : true}
                >
                  Submit
                </Button>
                <Button
                  className="button cancel-btn"
                  variant="outlined"
                  type="reset"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        </form>
      </Container>
      <hr className="divider" />
    </>
  );
};

export default AddUser;
