import { usestate, useEffect } from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "../navbar/NavbarElement";
import cimmytlogo from "../../Assets/Image/cimmyt.svg";
import dglogo from "../../Assets/Image/dglogo.svg";
import { Col, Row } from "react-bootstrap";

export default function HomeNavBar(props) {
  return (
    <>
      <Nav>
        <Row>
          <Col style={{ marginTop: "10px" }} xs={6} sm={6} md={6} lg={6}>
            <img
              className="image"
              // imglogo={require("../../Assets/Image/cimmyt.svg")}
              src={cimmytlogo}
              alt="cimmyt"
              style={{ width: "162px", height: "39px" }}
            />
            <span className="verticalSeparator"></span>
            <img
              className="image"
              src={dglogo}
              alt="digitalgreen"
              style={{ width: "213.37px", height: "27.86px" }}
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}></Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <NavBtn className="homenavbarbtn">
              <NavBtnLink to="/login">{"Login"}</NavBtnLink>
            </NavBtn>
          </Col>
        </Row>
      </Nav>
    </>
  );
}
