const LocalStorageConstants = {
    KEYS: {
      JWTToken: "JWTToken",
      user: "user",
      role: "role",
      org_id: "org_id",
      user_map: "user_map",
      error: "error",
      userId: "userId",
      sessionId: "sessionId"
    },
    ROLES: {
      CIMMYT_ADMIN: "admin_user",
      CIMMYT_NORMAL_ROOT: "client_user",
      CIMMYT_SUPER_USER: "super_user"
    }
  };
  
  export default LocalStorageConstants;
  