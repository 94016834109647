// Create a table to show activity logs of users using react bootstrap
import React, { useState, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import Typography from "@mui/material/Typography";
import "../../../Utils/common.css";
import "./activityLogs.css";
import { Row, Col, Container, CloseButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
  } from "@mui/material";


const { SearchBar, ClearSearchButton} = Search;
// const { ExportCSVButton } = CSVExport;

const UserActivityLogs = ({activityLogsData, getCanvasData}) => {
    const [activityLogs, setActivityLogs] = useState([]);

    console.log("activityLogs", activityLogsData);
    let navbar = useNavigate();

    // const handleClick = (e) =>{
    //     console.log("table clicked",e);
    // }

    // let data = activityLogsData.map((item,index)=>{
    //     return ({
    //         id: index+1,
    //         name: item.client_name,
    //         activity: item.client_name,
    //         date: item.client_name,
    //         loginTime: item.client_name,
    //         logoutTime: item.client_name,
    //         clientName: item.client_name,
    //         activityDescription: item.client_name,
    //         loginTime: item.client_name,
    //         logoutTime: "12:00:00",
    //         clientName: "Heineken",
    //         email: "benjamin@heineken.org",
    //         activityDescription: "view"

    //     })
    // })
    
    useEffect(() => {
        console.log("data", activityLogsData);
        setActivityLogs(activityLogsData);
    }, [activityLogsData])


    const handleView = (activity) => {
        //   console.log("clicked",e.target, row,rowIndex)
        //   if(e.target.innerText === "view"){
        //     handleShow()
        //   }
        getCanvasData(activity.id)
        // handleShow()
        }
    

    return (
        <div  >
           
                          <Container className="main-container">                
                            <Row > 
                            <Row  className="" >
                                <Col xl={2} md={4} sm={12} xs={12} >
                            <Typography className="titleText" variant="h4">
                            Activity Log
                             </Typography>
                                </Col>
                              
                            </Row>

                            <TableContainer component={Paper} style={{height: "483px",padding: "0px"}} className="table-container">
            {/* {clientData.length > 0 && ( */}
              <Table stickyHeader sx={{ minWidth: 650}} aria-label="simple table">
                <TableHead className="tablehead_bg_green">
                  <TableRow>
                    <TableCell >Sl.No</TableCell>
                    <TableCell  align="left">
                    Login Time
                    </TableCell>
                    <TableCell maxWidth="18%" align="left">
                    Logout Time
                    </TableCell>
                    <TableCell width="18%" align="left">
                    Activity Description
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activityLogs?.map((activity, index) => (
                    console.log("client in map", activity),
                    <TableRow
                      key={index}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">{activity?.login_time}</TableCell>
                      <TableCell align="left" >{activity?.logout_time}</TableCell>
                     
                      <TableCell className="view-details" align="left" onClick={ (e) => handleView(activity)}>
                        View
                        {/* {user.is_active ? "Active" : "Incative"} */}
                        {/* <SwitchKey eachUser={user} user={user} sendingActivityStatus={sendingActivityStatus} status={user.is_active} /> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            {/* )} */}
          </TableContainer>
                         
                            </Row>
                        </Container>
        </div>
    );
}

export default UserActivityLogs