import { usestate, useEffect } from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "./NavbarElement";
import cimmytlogo from "../../Assets/Image/cimmyt.svg";
import { useNavigate } from "react-router-dom";
import dglogo from "../../Assets/Image/dglogo.svg";
import { Col, Container, Row } from "react-bootstrap";
import "./adminNavbar.css";
import { flushLocalstorage } from "../../Utils/Common";
import HTTPService from "../../Services/HTTPService";
import UrlConstants from "../../Utils/Constants/UrlConstants";

export default function AdminNavbar() {

  const navigate = useNavigate();

  const logout = () => {
    let payload = {
      session: JSON.parse(localStorage.getItem("sessionId"))
    }
    let url = UrlConstants.base_url+"users/logout/";
    HTTPService(
      "POST",
      url,
      payload,
      false,
      true,
      false,
    )
      
      .then((res) => {
        console.log("res in logout", res);
        flushLocalstorage();
        navigate("/login")

      })
      .catch((err) => {
        console.log("err", err);
        flushLocalstorage();
        navigate("/login")
      });
    }


  const handleLogout = (e) => {
    e.preventDefault();
    logout();
    //  navigate("/login");
  };
  return (
    <div className="admin-navbar-container">
      <Nav>
        <Row>
          <Col xs={2} lg={2}>
            <div>
              <img
                className="image"
                // imglogo={require("../../Assets/Image/cimmyt.svg")}
                src={cimmytlogo}
                alt="cimmyt"
                style={{
                  width: "162px",
                  height: "39px",
                  "margin-left": "22px",
                  "margin-top": "9px",
                }}
              />
            </div>
          </Col>
  
          {/* Add crop, data, downlode in navbar */}
          <Col xs={8} lg={8} className="routes-container">
            <NavMenu>
              <NavMenu>
                <NavLink to="/admin/clientmanager" activeStyle>
                  Client Manager
                </NavLink>
                <NavLink to="/admin/usermanager" activeStyle>
                  User Manager
                </NavLink>
                <NavLink to="/admin/logs" activeStyle>
                  Logs
                </NavLink>
                <NavLink to="/admin/settings" activeStyle>
                  Settings
                </NavLink>
              </NavMenu>
            </NavMenu>
          </Col>
          <Col xs={2} lg={2}>
            <NavBtn onClick={handleLogout}>
              <NavBtnLink  to="/login">{"Logout"}</NavBtnLink>
            </NavBtn>
          </Col>
        </Row>
      </Nav>
    </div>
  );
}
