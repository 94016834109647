import React, { useEffect } from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import { TextField, Select, FormControl, InputLabel, Typography, MenuItem } from "@mui/material";
import { getData } from "country-list";
import { useState } from "react";
import HTTPService from "../../Services/HTTPService";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import { GetErrorKey, getUserLocal } from "../../Utils/Common";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";

import { GetErrorHandlingRoute } from "../../Utils/Common";
import PasswordCanvas from "../client/canvas/PasswordCanvas";
import CustomToast from "../../Utils/Toast/Toast";

const useStyles = {
    nameField: { width: "510px", marginLeft: "50px" },
    userIdField: { width: "510px", marginLeft: "46px" },
    clientField: { width: "510px", marginLeft: "50px", marginTop: "50px" },
    countryField: { width: "510px", marginLeft: "46px", marginTop: "50px" },
    heading: { marginLeft: "50px" },
    button: { width: "192px", height: "42px", marginLeft: "130px", "box-sizing": "boder-box", "background": "#FFFFFF", border: "1px solid #30572A", "border-radius": "4px" }
}
const UserProfile = (props) => {
    const [password_error_enforced, setPassword_error_enforced] = useState("")
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [clientName, setClientName] = useState("")
    const [country, setCountry] = useState("")
    const [isLoader, setIsLoader] = useState(false);
    const navigate = useNavigate();

    const countriesList = getData();
    const [show, setShow] = useState(false);

    //Toast toggling and setter
    const [showToast, setShowToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const toggleToast = () => {
        setShowToast(!showToast);
    };
    const toggleErrorToast = () => {
        setShowErrorToast(!showErrorToast);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const getUserProfile = (e) => {
        let id = getUserLocal();
        console.log("userid", id);
        setIsLoader(true)
        HTTPService(
            "GET",
            UrlConstants.base_url + UrlConstants.user_profile + id + "/",
            false,
            false,
            true
        ).then((response) => {
            setIsLoader(false)
            console.log(response)
            setName(response.data.first_name)
            setClientName(response.data.client.client_name)
            setCountry(response.data.country.code && response.data.country.name)
            setEmail(response.data.email)
        }).catch((err) => {
            setIsLoader(false)
            navigate(GetErrorHandlingRoute(err));
        })
    }

    //ok event function for the user profile passwrod change

    const handleUserProfilePassChange = (password) => {
        setIsLoader(true)
        console.log("user profile pass change")
        let urlToHit_reset = UrlConstants.base_url + UrlConstants.changePassEndPoint

        let payload = {
            "email_id": email ? email.trim() : "",
            "new_password": password,
            "reset": false,
            "reset_token": ""
        }
        HTTPService(
            "POST",
            urlToHit_reset,
            payload,
            false,
            true,
            false
        ).then((res) => {
            setPassword_error_enforced("")
            handleClose()
            setIsLoader(false)
            setToastMessage("Password is updated successfully.");
            setShowErrorToast(false);
            toggleToast();
            console.log(res)
        }).catch((e) => {
            // setIsLoader(false)
            // setToastMessage(e.message);
            // setShowToast(false)
            // toggleToast();

            let returnValues = GetErrorKey(e, Object.keys(payload));
            let errorKeys = returnValues[0];
            let errorMessages = returnValues[1];

            if (errorKeys.length > 0) {
                for (var i = 0; i < errorKeys.length; i++) {
                    switch (errorKeys[i]) {
                        case "new_password":
                            setPassword_error_enforced(e.response.data.new_password);
                            break;

                        default:
                            //   history.push(GetErrorHandlingRoute(e));
                            console.log("err in default");
                            navigator(GetErrorHandlingRoute(e));

                            break;
                    }
                }
                setIsLoader(false);
            } else {
                // setToastMessage("Oops, something went wrong!");
                // setShowErrorToast(true);
                setIsLoader(false);
                navigator(GetErrorHandlingRoute(e));
            }


        })
    }



    useEffect(() => {
        getUserProfile();
    }, [])
    return (
        <>
            {showToast ? (
                <CustomToast
                    toggleToast={toggleToast}
                    text={toastMessage}
                    success={true}
                />
            ) : showErrorToast ? (
                <CustomToast
                    toggleToast={toggleErrorToast}
                    text={toastMessage}
                    error={true}
                />
            ) : null}
            {isLoader ? <Loader /> : (
                <Container className="main-container">
                    <Row xs={12} lg={12} md={12} sm={12}>
                        <Typography
                            style={useStyles.heading}
                            className="titleText"
                            variant="h4">
                            User Profile
                        </Typography>
                    </Row>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <TextField
                                type="text"
                                id="standard-basic"
                                variant="standard"
                                label="Name"
                                style={useStyles.nameField}
                                value={name}
                                disabled
                            />
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <TextField
                                type="text"
                                id="standard-basic"
                                label="User Id"
                                variant="standard"
                                value={email}
                                style={useStyles.userIdField}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <TextField
                                type="text"
                                id="standard-basic"
                                variant="standard"
                                label="Client Name"
                                style={useStyles.clientField}
                                value={clientName}
                                disabled
                            />
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <FormControl
                                style={useStyles.countryField}
                                variant="standard"
                                disabled
                                required={false}
                            >
                                <InputLabel
                                    // className="require-input-label"
                                    id="demo-simple-select-required-label"
                                >
                                    Country
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required-label"
                                    value={country}
                                    label="Country"
                                >
                                    {countriesList.map((country, index) => (
                                        <MenuItem key={index} value={country.name}>
                                            {country.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row spacing={2}>
                        <Col className="btn-container" item xs={12} sm={12}>
                            <Button
                                variant="contained"
                                type="button"
                                style={useStyles.button}
                                onClick={() => {
                                    setPassword_error_enforced("")
                                    handleShow()
                                }
                                }
                            >
                                Change Password
                            </Button>
                        </Col>
                    </Row>
                </Container>)}
            <PasswordCanvas email={email} err={password_error_enforced} handleUserProfilePassChange={handleUserProfilePassChange} handleClose={handleClose} handleShow={handleShow} show={show} placement={"end"} topImage={false} bottomImage={false} topHeadingTitle={"Change Password"} headingWithForm="" topHeading={true} />

        </>
    )
}
export default UserProfile;