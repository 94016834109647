import React, { useRef } from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Col, Row } from "react-bootstrap";
import "./Login.css";
import cimmytlogo from "../../Assets/Image/CIMMYTLogologin.svg";
import loginimg from "../../Assets/Image/loginimg.svg";
import dglogo from "../../Assets/Image/dglogo.svg";
import Loader from "../Loader/Loader";
import NiceInputPassword from 'react-nice-input-password';
import { InputAdornment, InputLabel } from "@mui/material";
import validator from "validator";
import PasswordStrengthBar from 'react-password-strength-bar';
import RegexConstants from "../../Utils/Constants/RegexConstants";
import { GetErrorHandlingRoute, getTokenLocal, validateInputField } from "../../Utils/Common";
import HTTPService from "../../Services/HTTPService";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const useStyles = {
    // leftimage:{height: "836px"},
    formStyle: {},
    secondField: {},
    thirdField: {},
    forgotpasswordfield: {},
    buttonstyle: {},
    lastImage: {}
}






const TextFieldPassRePass = ({ setPassword_error_reset, setIsLoader, setPassChangeSuccess, setFirstTimeLogin, url, userid, token, disabledEmail, firstTimeLoginEmailId, resetLinkFromMail, okEvent, handleChangePass, handleRetypeChangePass, email, emailid, err }) => {
    // const email = useRef();
    // const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false)
    const [password, setPassword] = useState("");
    const [retypepassword, setRetypePassword] = useState("");
    const [passworderror, setPassError] = useState("")
    // const [isloader, setIsLoader] = useState(false)
    // const { password } = ;
    const navigator = useNavigate()
    const [disabledMailId, setDisableMailId] = useState("")
    const [temp_token, setTempToken] = useState("")
    const [usernameerrormessage, setUserError] = useState("");
    const [allPassCriteriaMatched, setAllPassCriteriaMatched] = useState(false)
    const [score, setScore] = useState(0)
    const [scoreFeedback, setScoreFeedback] = useState(['Weak', 'Weak', 'Okay', 'Good', 'Strong'])
    const [passwordInputType, setPasswordInputType] = useState("password")
    const [reTypePasswordInputType, setReTypePasswordInputType] = useState("password")
    

    const handlepassword = (e) => {
        setPassword_error_reset(null)
        let upper = /(?=.*?[A-Z])/;
        let lower = /(?=.*?[a-z])/
        let spl = /(?=.*?[#?!@$%^&*-])/
        let minLength = /.{8,}/
        let total = 0;
        let arr = [e.target.value.match(upper), e.target.value.match(lower), e.target.value.match(spl), e.target.value.match(minLength)]
        arr.map((item) => item ? total++ : total);

        setScore(total)
        // if (e.target.value.match(upper) || e.target.value.match(lower) || e.target.value.match(spl)) {
        // }
        // if (e.target.value.match(upper) && e.target.value.match(lower)) {
        //     setScore(3)
        // }
        // if (e.target.value.match(upper) && e.target.value.match(lower) && e.target.value.match(spl)) {
        //     setScore(4)
        // }
        // console.log(score)
        setPassword(e.target.value)
    }

    const handleRetypePassword = (e) => {
        setPassword_error_reset(null)
        setRetypePassword(e.target.value)
    }
    

    const handleEmail = (e) => {
        e.preventDefault();
        console.log(e.target.value);
        e.target.value = e.target.value.trim();
    }


    const handleSubmit = (e) => {
        setIsLoader(true)
        let urlTohit = url
        let payload
        console.log(userid, token)
        if (resetLinkFromMail) {
            payload = {
                "email_id": disabledEmail,
                "new_password": password,
                "reset": true,
                "reset_token": token
            }
        } else {
            payload = {
                "email_id": firstTimeLoginEmailId,
                "new_password": password,
                "reset": false,
                "reset_token": ""
            }
        }


        HTTPService(
            "POST",
            urlTohit,
            payload,
            false,
            resetLinkFromMail ? false : true,
            resetLinkFromMail ? true : temp_token
        ).then((res) => {
            console.log(res)
            setIsLoader(false)
            setPassChangeSuccess(true)
        }).catch((e) => {
            setIsLoader(false)
            navigator(GetErrorHandlingRoute(e));
        })
    }
    useEffect(() => {
        setDisableMailId(JSON.parse(localStorage.getItem("cimmyt_emailId")))
        setTempToken(JSON.parse(localStorage.getItem("temp_token")))
        // console.log(JSON.parse(localStorage.getItem("cimmyt_emailId"), "email"))
        console.log(email)
    }, [])
    return (
        <span className="mainDivForChangePass">
            <Row >
                <Col xs={12} sm={12} md={6} lg={6} >
                    {/* {(userid && token) ? */}
                    {/* <TextField
                            required
                            id="standard-basic"
                            type="email"
                            variant="standard"
                            className="textField"
                            disabled
                            value={disabledEmail} */}
                    {/* // onChange={handleEmail}
                        // onKeyDown={(e) => { if (e.key == ' ') { e.preventDefault() } }}
                        // inputRef={email}
                        // error={props.iserror || props.isuserSuspenderror}
                        // helperText={(props.iserror || props.isuserSuspenderror) ? props.errormessage : ""}
                        /> : */}
                    {/* disabledMailId ? */}
                    <TextField
                        required
                        id="standard-disabled"
                        label="Mail Id"
                        type="email"
                        variant="standard"
                        className="textField"
                        // disabled={props.userid ? true : false}
                        disabled
                        defaultValue={email}
                    // onChange={handleEmail}
                    // onKeyDown={(e) => { if (e.key == ' ') { e.preventDefault() } }}
                    // inputRef={email}

                    />
                    {/* : */}
                    {/* <TextField
                                required
                                id="standard-basic"
                                label="User name"
                                type="email"
                                variant="standard"
                                className="textField"
                                // disabled={props.userid ? true : false}

                                // onChange={handleEmail}
                                onKeyDown={(e) => { if (e.key == ' ') { e.preventDefault() } }}
                                inputRef={email}
                            // error={props.iserror || props.isuserSuspenderror}
                            // helperText={(props.iserror || props.isuserSuspenderror) ? props.errormessage : ""}
                            />} */}
                </Col>
            </Row>
            <Row >
                <Col style={useStyles.secondField} xs={12} sm={12} md={6} lg={6} >
                    <TextField
                        required
                        id="standard-basic"
                        label="Enter New Password"
                        type={passwordInputType}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment onClick={() => passwordInputType === "password" ? setPasswordInputType("text") : setPasswordInputType("password")}  position="end">
                                {
                                  passwordInputType === "password" ? <VisibilityOffIcon/> : <VisibilityIcon/>
                                  
                                }
                                
                              </InputAdornment>
                            ),
                          }}
                        value={password}
                        variant="standard"
                        className="textField password_with_checker"
                        onChange={handlepassword}
                        onKeyDown={(e) => { if (e.key == ' ') { e.preventDefault() } }}
                        error={err ? true : false}
                        helperText={(err) ? err : ""}

                    />
                    <span className="password_strength_checker_main_div" >
                        <span className="password_strength_checker_color_main_box" >
                            <div style={{ height: "3px", width: "20px", background: score >= 1 ? "#30572A" : "#99DA8F" }}></div>
                            <div style={{ height: "3px", width: "20px", background: score >= 2 ? "#30572A" : "#99DA8F", marginLeft: "5px" }}></div>
                            <div style={{ height: "3px", width: "20px", background: score >= 3 ? "#30572A" : "#99DA8F", marginLeft: "5px" }}></div>
                            <div style={{ height: "3px", width: "20px", background: score >= 4 ? "#30572A" : "#99DA8F", marginLeft: "5px" }}></div>
                            {/* <PasswordStrengthBar onChangeScore={(score, callback) => { */}
                            {/* }} minLength={8} scoreWordStyle={{ fontSize: "10px", margin: "0px", color: "rgb(137, 135, 146)", textAlign: "left" }} className="password_strength_main" style={{ width: "95px" }} barColors={["#99DA8F", score == 1 ? "#30572A" : "#99DA8F", score == 2 ? "#30572A" : "#99DA8F", score == 3 ? "#30572A" : "#99DA8F", score == 4 ? "#30572A" : "#99DA8F"]} password={password} />  */}
                        </span>
                        <p className="password_strength_checker_text_box">{scoreFeedback[score]}</p>
                    </span>
                </Col>
            </Row>
            <Row >
                <Col style={useStyles.thirdField} xs={12} sm={12} md={6} lg={6} >
                    <TextField
                        required
                        id="standard-basic"
                        label="Re-type Password"
                        type={reTypePasswordInputType}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment onClick={() => reTypePasswordInputType === "password" ? setReTypePasswordInputType("text") : setReTypePasswordInputType("password")}  position="end">
                          { reTypePasswordInputType === "password" ? < VisibilityOffIcon/> : <VisibilityIcon/> }
                              </InputAdornment>
                            ),
                          }}
                        value={retypepassword}
                        variant="standard"
                        className="textField"
                        onChange={handleRetypePassword}
                        onKeyDown={(e) => { if (e.key == ' ') { e.preventDefault() } }}
                        error={err ? true : false}
                        helperText={(err) ? err : ""}
                    />

                    {/* <NiceInputPassword
                   label="Re-type Password"
                   name="password"
                   LabelComponent={InputLabel}
                   InputComponent={TextField}
                   InputComponentProps={{
                    variant:"standard",
                    id:"standardbasic"
                   }}
                  value={password}
                  showSecurityLevelBar
                  // securityLevels={securityLevels}
                  onChange={handlepassword}
                  /> */}
                </Col>
            </Row>
            <Row >
                <Col className="password_mandatory_column" xs={12} sm={12} md={6} lg={6}>
                    <span>Password should be:</span>
                    <ul className="uordered_list_for_password" >
                        <li>Min 8 characters</li>
                        <li>Min 1 lower case and min 1 upper case</li>
                        <li>Min 1 special characters</li>
                    </ul>
                </Col>
            </Row>

            <Row className="row_for_the_btn_grp_in_change_pass_screen" >
                {/* {(usernameoremail && (password.length >= 8)) ? */}

                {score >= 4 && (password === retypepassword) ? <Col xs={12} sm={12} md={6} lg={6}>
                    <Button variant="contained"
                        onClick={() => okEvent(password)}
                        // onClick={handleSubmit}
                        type="submit"
                        // style={{ marginTop: "50px" }}
                        className="submitbtn">Submit</Button>
                </Col> : <Col xs={12} sm={12} md={6} lg={6} >
                    <Button variant="outlined" disabled
                        type="submit"
                        // style={{ marginTop: "50px" }}
                        className="submitbtn disabled-submit-btn-login">Submit</Button>

                </Col>}
            </Row>

        </span >
    )
}

export default TextFieldPassRePass