import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";

import { Container, Row, Col } from "react-bootstrap";
import { getData, getCode } from "country-list";
import "../../../Utils/common.css";
import "./changePassword.css";
import {
  descriptionRegex,
  nameRegex,
  validate,
  NO_SPACE_REGEX,
  nameField,
} from "../../../Utils/Common";
import HTTPService from "../../../Services/HTTPService";
import validator from "validator";
import RegexConstants from "../../../Utils/Constants/RegexConstants";
import UrlConstants from "../../../Utils/Constants/UrlConstants";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const SuperUserChangePassword = (props) => {

  // print props
  console.log("props in superUserChangePassword", props)

  
  const [score, setScore] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordInputType, setPasswordInputType] = useState("password")
  const [reTypePasswordInputType, setReTypePasswordInputType] = useState("password")
  
 

  const handlepassword = (e) => {
    let upper = /(?=.*?[A-Z])/;
    let lower = /(?=.*?[a-z])/;
    let spl = /(?=.*?[#?!@$%^&*-])/;
    let minLength = /.{8,}/;
    let total = 0;
    let arr = [
      e.target.value.match(upper),
      e.target.value.match(lower),
      e.target.value.match(spl),
      e.target.value.match(minLength),
    ];
    arr.map((item) => (item ? total++ : total));

    setScore(total);
    setErrorMessage("");
    props.setNewPasswordError("");

    if (validate(e.target.value, NO_SPACE_REGEX)) {
      setPassword(e.target.value);
    }
    

  };

  const handlePasswordSubmit = () => {
    console.log("in handlePasswordSubmit", props)
    if (score < 4) {
      setErrorMessage("Password is not strong.");
      return;
    }
    else if (password !== confirmPassword) {
      setErrorMessage("Password is not matching with New password");
      return;
    }
    props.handlePasswordSubmit(password);
    setScore(0)
  };



  const handleConfirmPassword = (e) => {
    setErrorMessage("");
    setConfirmPassword(e.target.value);
  };

  let submitBtnClassName = score>=4 && confirmPassword===password ? "button submit-btn" : "button disabled-submit-btn";

  useEffect(() => {
    setPassword("");
    setConfirmPassword("");
  }, [props.resetInput]);


  return (
    <>
      <Container className="main-container">
       
          <div>
            <Row xs={12} lg={12} md={12} sm={12}>
              <Typography className="titleText" variant="h4">
                Change Password
              </Typography>
            </Row>
            <Row container spacing={2}>
              <Col style={{ paddingRight: "20px" }} item xs={12} sm={6}>
                <TextField
                  style={{}}
                  onChange={handlepassword}
                  value={password}
                  variant="standard"
                  type={passwordInputType}
                  InputProps={{
                      endAdornment: (
                        <InputAdornment onClick={() => passwordInputType === "password" ? setPasswordInputType("text") : setPasswordInputType("password")}  position="end">
                          {
                            passwordInputType === "password" ? <VisibilityOffIcon/> : <VisibilityIcon/>
                            
                          }
                          
                        </InputAdornment>
                      ),
                    }}
                  id="name"
                  name="name"
                  label="Enter New Password"
                  fullWidth
                  autoComplete="given-name"
                  error={props.newPasswordError ? true : false}
                  helperText={props.newPasswordError}
                />
                <span className="password_strength_checker_main_div password_strength_checker_admin_change_password">
                  <span className="password_strength_checker_color_main_box">
                    <div
                      style={{
                        height: "3px",
                        width: "20px",
                        background: score >= 1 ? "#30572A" : "#99DA8F",
                      }}
                    ></div>
                    <div
                      style={{
                        height: "3px",
                        width: "20px",
                        background: score >= 2 ? "#30572A" : "#99DA8F",
                        marginLeft: "5px",
                      }}
                    ></div>
                    <div
                      style={{
                        height: "3px",
                        width: "20px",
                        background: score >= 3 ? "#30572A" : "#99DA8F",
                        marginLeft: "5px",
                      }}
                    ></div>
                    <div
                      style={{
                        height: "3px",
                        width: "20px",
                        background: score >= 4 ? "#30572A" : "#99DA8F",
                        marginLeft: "5px",
                      }}
                    ></div>
                    {/* <PasswordStrengthBar onChangeScore={(score, callback) => { */}
                    {/* }} minLength={8} scoreWordStyle={{ fontSize: "10px", margin: "0px", color: "rgb(137, 135, 146)", textAlign: "left" }} className="password_strength_main" style={{ width: "95px" }} barColors={["#99DA8F", score == 1 ? "#30572A" : "#99DA8F", score == 2 ? "#30572A" : "#99DA8F", score == 3 ? "#30572A" : "#99DA8F", score == 4 ? "#30572A" : "#99DA8F"]} password={password} />  */}
                  </span>
                  <p className="password_strength_checker_text_box">
                    {/* {scoreFeedback[score]} */}
                  </p>
                </span>
              </Col>
              <Col
                style={{ paddingLeft: "10px", paddingRight: "25px" }}
                item
                xs={12}
                sm={6}
              >
                <TextField
                  // onChange={handleEmail}
                  onKeyDown={(e) => {
                    if (e.key == " ") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => handleConfirmPassword(e)}
                  value={confirmPassword}
                  type={reTypePasswordInputType}
                  InputProps={{
                      endAdornment: (
                        <InputAdornment onClick={() => reTypePasswordInputType === "password" ? setReTypePasswordInputType("text") : setReTypePasswordInputType("password")}  position="end">
                          { reTypePasswordInputType === "password" ? < VisibilityOffIcon/> : <VisibilityIcon/> }
                        </InputAdornment>
                      ),
                    }}
                  variant="standard"
                  id="name"
                  name="Email"
                  label="Re-type password"
                  fullWidth
                  autoComplete="given-name"
                  error={errorMessage ? true : false}
                  helperText={errorMessage}
                />
              </Col>
            </Row>
            <Row>
              <p className="guidance_text">
                Password should be:
                <br />
                Min 8 characters | Min 1 lower case and min 1 upper case | Min 1
                special characters.
              </p>
            </Row>
            <Row spacing={2}>
              <Col className="btn-container" item xs={12} sm={12}>
                <Button
                  className={submitBtnClassName}
                  variant="contained"
                  type="button"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={handlePasswordSubmit}
                    disabled={(score>=4 && confirmPassword === password) ? false : true}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
      </Container>
    </>
  );
};

export default SuperUserChangePassword;
