import React from "react";
import { Col , Row} from "react-bootstrap";
import { Link } from "react-router-dom";
import './footer.css'

export default function Footer(props) {
    return(
        <>
        <div>
        <div style={{marginTop: '120px'}}> </div>
        <footer className="footer">
            <Row>
            <Col xs={12} sm={12} md={3} lg={3}>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
            {(props.disableHomeLink)? <a className = "footerlink" to=''>About CIMMYT</a>
             :<a className="footerlink" href='https://www.cimmyt.org/' target="_blank">About CIMMYT</a>}
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
            {(props.disableLegalLink)?<a className = "footerlink" to='#'>About Digital Green</a>
            :<a className="footerlink" href="https://www.digitalgreen.org/" target='_blank'>About Digital Green</a>}
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
            {(props.disableContactLink)? <a className = "footerlink" to='#'>Contact Us</a>
            // :<a className = "footerlink" href="https://www.cimmyt.org/contact/" target='_blank'>Contact Us</a>}
            :<div className = "footerlink" onClick={()=>props.setIsContact(true)} target='_blank'>Contact Us</div>}
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
            </Col>
            </Row>
        </footer>
        </div>
        </>
    )
}