import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Container, Row, Col } from "react-bootstrap";
import { getData, getCode } from "country-list";
import "../../Utils/common.css";
import "./addClient.css";
import HTTPService from "../../Services/HTTPService";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import { descriptionRegex, nameField, nameRegex, validate } from "../../Utils/Common";
import RegexConstants from "../../Utils/Constants/RegexConstants";

const AddClient = (props) => {
  // Create a form which accept name country and discription

  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState("");
  // const [showToast, setShowToast] = useState(false);

  const countries = getData();

  let submitBtnClassName = "button disabled-submit-btn";

  if (name && country) {
    submitBtnClassName = "button submit-btn";
  }

  // function to toggel toast
  // const toggleToast = () => {
  //   setShowToast(!showToast);
  // };
  const handleSubmit = (e) => {
    // setShowToast(true);

    let data = {
      name: name,
      country: country,
      description: description,
    };
    console.log("data in submit", data);
    // let url = UrlConstants.base_url + "clients/client/";
    props.addClient(data, e);
  };

  const handleCancel = () => {
    props.resetErrorMessage()
    setName("");
    setCountry("");
    setDescription("");
  };

  const handleName = (e) => {
    props.resetErrorMessage()
    // if (validateName(e.target.value)) {
    //   setName(e.target.value);
    // }
    let valueArray = e.target.value.split(" ");
    let validText = true;
    for(let i in valueArray){
      if(valueArray[i-1]===""){
        validText = false;
        break;
      }
    }
    console.log(validText)
    if (validate(e.target.value, nameField) && (validText) ) {
      setName(e.target.value);
    }
}
 
 
  // handle discription
  const handleDescription = (e) => {
    props.resetErrorMessage()
    // let valueArray = e.target.value.split(" ");
    // let validText = true;
    // for(let i in valueArray){
    //   if(valueArray[i-1]===""){
    //     validText = false;
    //     break;
    //   }
    // }
    // console.log(validText)
    // setDescription(e.target.value)
    let twoSpaceRegex = RegexConstants.TWO_SPACE_REGEX
      if(e.target.value[0]!==" "){
        setDescription(e.target.value.replace(twoSpaceRegex," "))
      }
// if(validText){

// }
  };

  function onClickOfEnter() {
    if (window.event.keyCode == 13) {
        // console.log("ENTER PRESSED");
        window.event.preventDefault();
        window.event.stopPropagation();
        return false;
    }
}

  
  useEffect(() => {
    // set all state empty
    setName("");
    setCountry("");
    setDescription("");
  }, [props.clearState]);

  console.log("props", props);

  return (
    <>
      <Container className="main-container">
        {/* {showToast ? (
          <CustomToast
            toggleToast={toggleToast}
            color={"#30572a"}
            backgroundColor={"#30572A"}
            text={"The client is added successfully."}
          />
        ) : null} */}
        <Row xs={12} lg={12} md={12} sm={12}>
          <Typography className="titleText" variant="h4">
            Add New Client
          </Typography>
        </Row>
        <form>
          <Row container spacing={2} style={{ paddingRight: "15px" }}>
            <Col item xs={12} sm={6}>
              <TextField
                onChange={handleName}
                value={name}
                variant="standard"
                id="name"
                name="name"
                label="Client Name"
                fullWidth
                autoComplete="given-name"
                error={props.clientNameErrorMessage ? true : false}
                helperText={props.clientNameErrorMessage}
              />
            </Col>
            <Col item xs={12} sm={6} style={{ padding: "0 0 0 20px" }}>
              <FormControl variant="standard" className="country-input">
                <InputLabel
                  className="require-input-label"
                  id="demo-simple-select-required-label"
                >
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required-label"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  label="Country *"
                >
                  {countries.map((country, index) => (
                    <MenuItem key={index} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Row className="description" item xs={12}>
              <Col item xs={12}>
                <TextField
                  onChange={handleDescription}
                  onKeyDown={onClickOfEnter}
                  value={description}
                  multiline
                  variant="standard"
                  id="description"
                  name="description"
                  label="Description"
                  fullWidth
                  autoComplete="shipping address-line1"
                  error={props.clientDescriptionErrorMessage ? true : false}
                  helperText={props.clientDescriptionErrorMessage}
                  inputProps={{ maxLength: 250 }}
                />
              </Col>
            </Row>

            <Row spacing={2}>
              <Col className="btn-container" item xs={12} sm={12}>
                <Button
                  className={submitBtnClassName}
                  variant="contained"
                  type="button"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={handleSubmit}
                  disabled={name && country ? false : true}
                >
                  Submit
                </Button>
                <Button
                  className="button cancel-btn"
                  variant="outlined"
                  type="reset"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        </form>
      </Container>
      <hr className="divider" />
    </>
  );
};

export default AddClient;
