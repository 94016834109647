import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import OffCanvasLogs from "../../components/client/canvas/CanvasForLogs";
import OffCanvasUserLogs from "../../components/client/canvas/CanvasForUserLogs ";
import Loader from "../../components/Loader/Loader";
import UserActivityLogs from "../../components/user/ActivityLogs/ActivityLogs";
import HTTPService from "../../Services/HTTPService";
import { GetErrorHandlingRoute } from "../../Utils/Common";
import UrlConstants from "../../Utils/Constants/UrlConstants";
// import "./logs.css";


const UserLogs = () => {

    const [activityLogsData, setActivityLogsData] = React.useState([]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [canvasData, setCanvasData] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
console.log("show",show)
    let navigate = useNavigate()
    
    

    const getAllLogs = () => {
      let userId = JSON.parse(localStorage.getItem("userId"))

        let url = UrlConstants.base_url + `main/activity_logs_list/?user=${userId}`;
        setLoader(true);
        HTTPService("GET", url, false, false, true, false)
          .then((res) => {
            setLoader(false);
            console.log("res in get user logs", res);
            if (res.status === 200) {
                setActivityLogsData(res.data);
            }
          })
          .catch((err) => {
            console.log("err", err);
            setLoader(false);
            navigate(GetErrorHandlingRoute(err));
          });
      };

      const getCanvasData = (id) => {
        let url = UrlConstants.base_url + `main/activity_logs/?session=${id}`;
        setLoader(true);
        HTTPService("GET", url, false, false, true, false)

          .then((res) => {
            setLoader(false);
            console.log("res in get canvas data", res);
            if (res.status === 200) {
              setCanvasData(res.data);
              handleShow();
            }
          }
          )
          .catch((err) => {
            console.log("err", err);
            setLoader(false);
            navigate(GetErrorHandlingRoute(err));
          });
      };



      useEffect(() => {
        getAllLogs();
        }, []);

    return (
      <Container className="client-manager-container">
        {loader ? <Loader /> : null}
            <UserActivityLogs getCanvasData={getCanvasData} activityLogsData={activityLogsData} />
            <OffCanvasUserLogs canvasData={canvasData} show={show} handleShow={handleShow} handleClose={handleClose} placement={"end"} />
        </Container>
    )
}

export default UserLogs;
