import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OffCanvasLogs from "../../components/client/canvas/CanvasForLogs";
import AddData from "../../components/client/setting/AddData";
import DisplayCrop from "../../components/client/setting/DisplayCropData";
import Loader from "../../components/Loader/Loader";
import ActivityLogs from "../../components/Logs/ActivityLogs";
import SuperUserChangePassword from "../../components/super-user/settings/ChangePassword";
import HTTPService from "../../Services/HTTPService";
import { GetErrorHandlingRoute, GetErrorKey } from "../../Utils/Common";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import CustomToast from "../../Utils/Toast/Toast";
// import "./logs.css";

const SuperUserSettings = () => {
  const [loader, setLoader] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [resetInput, setResetInput] = useState(0);
  const [newPasswordError, setNewPasswordError] = useState("");

  let navigate = useNavigate();

  const toggleToast = () => setShowToast(false);

  const handlePasswordSubmit = (password) => {
    setLoader(true);
    let url = UrlConstants.base_url + "users/change_password/";
    let data = {
      email_id: JSON.parse(localStorage.getItem("email_id")),
      new_password: password,
      reset: false,
    };
    HTTPService("POST", url, data, false, true)
      .then((res) => {
        setLoader(false);
        console.log(res);
        if (res.status === 201) {
          setToastMessage("Password is updated successfully.");
          setShowToast(true);
          setResetInput(resetInput + 1);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);

        let returnValues = GetErrorKey(err, Object.keys(data));
        let errorKeys = returnValues[0];
        let errorMessages = returnValues[1];

        if (errorKeys.length > 0) {
          for (var i = 0; i < errorKeys.length; i++) {
            switch (errorKeys[i]) {
              case "new_password":
                setNewPasswordError(err.response.data[errorKeys[i]]);
                console.log("new_password", errorMessages[i]);

                break;
              //     case "description":
              //       setClientDescriptionErrorMessage(errorMessages[i]);
              //       break;
              //     case "country":
              //       setClientCountryErrorMessage(errorMessages[i]);
              //       break;

              default:
                //   history.push(GetErrorHandlingRoute(e));
                console.log("err in default");
                navigate(GetErrorHandlingRoute(err));
                break;
            }
          }
          setLoader(false);
        } else {
          // setToastMessage("Oops, something went wrong!");
          // setShowErrorToast(true);
          setLoader(false);
          navigate(GetErrorHandlingRoute(err));
        }
      });
  };

  return (
    <div>
      {showToast ? (
        <CustomToast text={toastMessage} toggleToast={toggleToast} />
      ) : null}
      <Container className="client-manager-container">
        {loader ? <Loader /> : null}
        <SuperUserChangePassword resetInput={resetInput} handlePasswordSubmit={handlePasswordSubmit} newPasswordError={newPasswordError} setNewPasswordError={setNewPasswordError} />
      </Container>
    </div>
  );
};

export default SuperUserSettings;
