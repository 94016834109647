import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getData, getCode } from "country-list";
import "./clientManager.css";
import HTTPService from "../../Services/HTTPService";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import { GetErrorHandlingRoute, GetErrorKey } from "../../Utils/Common";
import Loader from "../../components/Loader/Loader";
import CustomToast from "../../Utils/Toast/Toast";
import AddUser from "../../components/client/UserManager/AddUser";
import DisplayUser from "../../components/client/UserManager/DisplayUser";
import { Navigate, useNavigate } from "react-router-dom";

const UserManager = () => {
  const [allClient, setAllClient] = useState([]);
  const [allUser, setAllUser] = useState([])
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [clientNameErrorMessage, setClientNameErrorMessage] = useState(null);
  const [clientCountryErrorMessage, setClientCountryErrorMessage] =
    useState(null);
  const [clientDescriptionErrorMessage, setClientDescriptionErrorMessage] =
    useState(null);
  const [userNameErrorMessage, setUserNameErrorMessage] = useState(null);
  const [userEmailErrorMessage, setUserEmailErrorMessage] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [clearState, setClearState] = useState(0);

  // state for loder
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate()

  let bodyFormData = new FormData();

  const toggleToast = () => {
    setShowToast(!showToast);
    console.log("success toast");
  };

  const toggleErrorToast = () => {
    setShowErrorToast(!showErrorToast);
  };

  const checkJwtAuth = () => { };

  const resetErrorMessage = ()=>{
    console.log("reseting error messages")

    setClientNameErrorMessage(null);
    setClientCountryErrorMessage(null);
    setClientDescriptionErrorMessage(null);
    setUserEmailErrorMessage(null)
    setUserNameErrorMessage(null)
    // print all errro message
    console.log("clientNameErrorMessage",clientNameErrorMessage)
    console.log("clientCountryErrorMessage",clientCountryErrorMessage)
    console.log("clientDescriptionErrorMessage",clientDescriptionErrorMessage)
    console.log("userEmailErrorMessage",userEmailErrorMessage)
  }

  const addUser = (data, e) => {
    // set all error message null
    setClientNameErrorMessage(null);
    setClientCountryErrorMessage(null);
    setClientDescriptionErrorMessage(null);
    setUserEmailErrorMessage(null)

    

    let countries = getData();
    console.log("user Data", data);
    let countryObj = data.country;

    if (data.name && data.country && data.email && data.client) {
      // Make a post request to the server to add the client
      console.log("user Data", data);
      console.log("countryObj", countryObj);
      for (let i of countries) {
        if (i.name === data.country) {
          console.log("i", i);
          countryObj = i;
          break;
        }
      }

      let payloadData = {
        email: data.email,
        first_name: data.name,
        country: countryObj,
        description: data.description,
        client: data.client,
        created_by: JSON.parse(localStorage.getItem("userId")),
        role: "client_user"
      };
      let url = UrlConstants.base_url + "users/user/";
      setLoader(true);

      HTTPService("POST", url, payloadData, false, true, false)
        .then((res) => {
          console.log("res", res);
          //   on successeful responce display toast
          if (res.status === 201) {
            setToastMessage("User is added successfully.");
            setShowErrorToast(false);
            toggleToast();
            getAllClient();
            getAllUser();
            setClearState(clearState + 1);
            setLoader(false);
            return;
          }

          return;
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          let returnValues = GetErrorKey(err, Object.keys(payloadData));
          let errorKeys = returnValues[0];
          let errorMessages = returnValues[1];

          if (errorKeys.length > 0) {
            for (var i = 0; i < errorKeys.length; i++) {
              console.log("error" ,errorKeys[i], errorMessages[i])
              switch (errorKeys[i]) {
                case "first_name":
                  setUserNameErrorMessage(errorMessages[i]);

                  break;
                case "description":
                  setClientDescriptionErrorMessage(errorMessages[i]);
                  break;
                case "country":
                  setClientCountryErrorMessage(errorMessages[i]);
                  break;
                case "email":
                  setUserEmailErrorMessage(errorMessages[i]);
                  break;
                case "client_name":
                  setClientNameErrorMessage(errorMessages[i]);
                  break;

                default:

                  //   history.push(GetErrorHandlingRoute(e));
                  navigate(GetErrorHandlingRoute(err));

                  break;
              }
            }

          } else {

            // setToastMessage("Oops, something went wrong!");
            // setShowErrorToast(true);
            setLoader(false);

          }
          //   print all error message
          console.log("clientNameErrorMessage", clientNameErrorMessage);
          console.log("clientCountryErrorMessage", clientCountryErrorMessage);
          console.log(
            "clientDescriptionErrorMessage",
            clientDescriptionErrorMessage
          );
        });
    }
  };

  //   create a funtion to get all client
  const getAllUser = () => {
    let url = UrlConstants.base_url + "users/user/";
    // setLoader(true);
    HTTPService("GET", url, false, false, true, false)
      .then((res) => {
        console.log("res in get all client", res);
        if (res.status === 200) {
          setAllUser(res.data);
          setClearState(true);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
        navigate(GetErrorHandlingRoute(err));

        // setToastMessage("Oops, something went wrong!");
        // setShowErrorToast(true);
      });
  };

  const getAllClient = () => {
    let url = UrlConstants.base_url + "clients/client/?active_clients=True";
    setLoader(true);
    HTTPService("GET", url, false, false, true, false)
      .then((res) => {
        console.log("res in get all client", res);
        if (res.status === 200) {
          setAllClient(res.data);
          setClearState(true);
          // setLoader(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
        navigate(GetErrorHandlingRoute(err))
        // setToastMessage("Oops, something went wrong!");
        // setShowErrorToast(true);

      });
  };

  //method to toggle activity of the user
  const sendingActivityStatus = (status, client) => {
    let url = UrlConstants.base_url + UrlConstants.user_status_toggle_end_point;
    let payload = {
      id: client.id,
      "is_active": status
    }
    setLoader(true);
    HTTPService("PUT", url, payload, false, true, "")
      .then((res) => {
        setLoader(false)
        if (res.status === 201) {
          console.log("res in get all client", res);
          // setAllClient(res.data);
          setLoader(false)
          setAllUser([...res.data]);
          // setToastMessage("User status changed successfully.");
          // setShowErrorToast(false);
          // updateAllUserWthLatestStatus(res.data)
          // toggleToast();
          // setShowToast(true);
          // return true
        }
      })
      .catch((err) => {
        console.log("err", err);
        /*
        setToastMessage("Oops, something went wrong!");
        setShowErrorToast(true);*/
        setLoader(false);
        navigate(GetErrorHandlingRoute(err))
      });
  }

  //   on page load call getAllClient funtion
  useEffect(() => {
    getAllClient();
    getAllUser();
    setClearState(0);
  }, []);
  console.log("allClient", allClient);

  return (
    <>
      {showToast ? (
        <CustomToast
          toggleToast={toggleToast}
          text={toastMessage}
          success={true}
        />
      ) : showErrorToast ? (
        <CustomToast
          toggleToast={toggleErrorToast}
          text={toastMessage}
          error={true}
        />
      ) : null}
      {loader ? <Loader /> : null}

      <Container className="client-manager-container">
        <Row xs={12} sm={12} md={12} lg={12}>
          <Col>
            <AddUser
              clearState={clearState}
              addUser={addUser}
              client={allClient}
              clientNameErrorMessage={clientNameErrorMessage}
              clientCountryErrorMessage={clientCountryErrorMessage}
              clientDescriptionErrorMessage={clientDescriptionErrorMessage}
              userNameErrorMessage={userNameErrorMessage}
              userEmailErrorMessage={userEmailErrorMessage}
              resetErrorMessage={resetErrorMessage}
              setClientNameErrorMessage={setClientNameErrorMessage}
              setClientCountryErrorMessage={setClientCountryErrorMessage}
              setClientDescriptionErrorMessage={setClientDescriptionErrorMessage}
              setUserEmailErrorMessage={setUserEmailErrorMessage}
            />
            <DisplayUser sendingActivityStatus={sendingActivityStatus} allUser={allUser} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserManager;
