const UrlConstants = {
    base_url: "https://cimmyt.digitalgreen.org/be/",
    main_url: "main/site/content/",
    users: "users/login/",
    main: "main/",
    user_profile: "users/user/",
    activity_toggle_end_point: "clients/client/set_client_status/",
    crop_name: "main/crops/",
    data_download_post_end_point: "main/crop_data/",

    client_status_toggle_end_point: "clients/client/set_client_status/",
    user_status_toggle_end_point: "users/user/set_user_status/",
    reset_password_mail_link: "users/reset_password/",
    validateAndGetMailId: "users/validate_reset_password_link/",
    changePassEndPoint: "users/change_password/"
    // site_url:"",
}
export default UrlConstants