import React, { useState, useMemo } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from "@mui/material/Button";
import "./Success.css";
import successimage from "../../Assets/Image/success.svg"
const useStyles = {
    btncolor: { color: "#30572A", "border-color": "#30572A", "border-radius": "4px" },
    marginrowtop: { "margin-top": "30px", },
    marginrowtop50: { "margin-top": "50px", margin: "50px auto 0px auto" },
    marginrowtop20: { "margin-top": "20px" },
    mainDiv: { width: "fit-content", margin: "auto" }
};
export default function Success(props) {
    return (
        <div style={useStyles.mainDiv}>
            <Row style={useStyles.marginrowtop50}>
                <Col xs={12} sm={12} md={12} lg={12} >
                    <div className="mainheadingsuccess">
                        {props.heading}
                    </div>
                </Col>
            </Row>
            <Row style={useStyles.marginrowtop}>
                <Col xs={12} sm={12} md={12} lg={12} >
                    <img
                        className='imageStyle'
                        src={successimage}
                        alt="new"
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} style={useStyles.marginrowtop20}>
                    <div className="secondmainheadingsuccess">
                        {props.imageText}
                    </div>
                </Col>
            </Row>
            <Row style={useStyles.marginrowtop20}>
                <Col xs={12} sm={12} md={12} lg={12} >
                    <div className="thirdmainheadingsuccess">
                        {props.msg}
                    </div>
                </Col>
            </Row>
            <Row style={useStyles.marginrowtop50}>
                <Col xs={12} sm={12} md={12} lg={12} style={{ margin: "auto", display: "inherit" }}>
                    <Button onClick={() => props.okevent()} variant="contained" className="submitbtn" style={{ 'text-transform': 'uppercase', width: "192px" }}>
                        <span >{props.btntext}</span>
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
