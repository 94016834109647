import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ChangePassword from '../../login/ChangePassword';
import TextFieldPassRePass from '../../text-field-with-pass-retype-pass/TextFieldPassRePass';
import styles from "./password_canvas.module.css"
import cimmytTopLogo from "../../../Assets/Image/CIMMYTLogologin.svg"
import dgBottomLogo from "../../../Assets/Image/dglogo.svg"
import UrlConstants from '../../../Utils/Constants/UrlConstants';
import Loader from '../../Loader/Loader';
function PasswordCanvas({ name, handleClose, isLoader, handleShow, show, topImage, bottomImage, topHeading, headingWithForm, topHeadingTitle, email, handleUserProfilePassChange, err, ...props }) {
    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    // to use this component onCick handleShow function should be called
    return (
        <>
            {/* <Button variant="primary" onClick={handleShow} className="me-2">
                {name}
            </Button> */}

            <Offcanvas style={{ margin: "auto" }} className={`${styles.canvas}`} show={show} onHide={handleClose} {...props}>
                {topHeading ? <Offcanvas.Header className={styles.header} closeButton>
                    <Offcanvas.Title className={styles.canvas_title} >{topHeadingTitle}</Offcanvas.Title>
                </Offcanvas.Header> : ""}

                {topHeading && <Row style={{ margin: "0px auto" }}>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <hr className={styles.hrLine} />
                    </Col>
                </Row>}
                {topImage && <Row className={topHeading ? styles.imgStyleWithTopHeading : styles.imgStyleWithoutTopHeading}>
                    <Col style={{ margin: "auto" }} xs={12} sm={12} md={6} lg={6}>
                        <span><img src={cimmytTopLogo} alt="top_image" /></span>
                    </Col>
                </Row>
                }
                {/* <Row>
                    <Col> */}
                {/* {headingWithForm ? <Row style={{ border: "1px solid red" }}>
                    <Col>
                    </Col>
                </Row> : ""} */}
                <span className={topHeading ? styles.leftAlignMainDiv : styles.centerAlignMainDiv} >
                    {headingWithForm ? <div className={styles.titleNearForm}> {headingWithForm}</div> : <div style={{ marginTop: "50px" }}></div>}
                    <TextFieldPassRePass err={err} email={email} okEvent={(pass) => handleUserProfilePassChange(pass)} />
                </span>
                {/* </Col>
                </Row> */}
                {bottomImage && <Row style={{}}>
                    <Col style={{ margin: "auto" }} xs={12} sm={12} md={6} lg={6}>
                        <div><img src={dgBottomLogo} alt="bottom_image" /></div>
                    </Col>
                </Row>}

            </Offcanvas>
        </>
    );
}
export default PasswordCanvas;

