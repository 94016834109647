import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import "./displayCropData.css";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import SwitchKey from "../../toggle-button/Switch";
// import Switch from "../toggle-button/Switch";
// import HTTPService from "../../Services/HTTPService";
// import SwitchKey from "../toggle-button/Switch";
// import PasswordCanvas from "./canvas/PasswordCanvas";

export default function DisplayCrop({ updatedData, sendingCropDataActivityStatus }) {
  const [cropData, setCropData] = useState([]);
  
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "black",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "black",
    },
  }));

  function popupOverlayHandler() {
    let div = document.getElementById("popover-basic");
    console.log(div)
    if (div) div.classList.remove("show")
  }

  // console.log('crop data', cropData)
  useEffect(() => {
    setCropData([...updatedData]);
  }, [updatedData]);

  console.log("updated data", updatedData)
  let descriptionClass = "description-column";
  
  return (
    <Container className="display-client-container">

      <Row>
        <Col sx={12} sm={12} md={12} xl={12}>
          <Typography className="titleText" variant="h4">
          Updated data
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col sx={12} sm={12} md={12} xl={12}>
          <TableContainer component={Paper} onScroll={() => popupOverlayHandler()} className="table-container crop_data_table_container">
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="display_crop_tablehead tablehead_bg_green">
                <TableRow>
                  <TableCell width="10%">Sl.No</TableCell>
                  <TableCell width="15%" align="left">
                  Client Org
                  </TableCell>
                  <TableCell width="15%" align="left">
                  Country
                  </TableCell><TableCell width="15%" align="left">
                  Crop Name
                  </TableCell><TableCell width="15%" align="left">
                  Season
                  </TableCell>
                  
                  <TableCell width="12%" align="left">
                    Active/Inactive
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="display_crop_tablebody">
                {cropData?.map((crop, index) => (
                  <TableRow
                    key={index}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{crop?.client}</TableCell>
                    <TableCell align="left">{crop?.country?.name}</TableCell>
                    <TableCell
                    //   className="description-column"
                    //   width="46%"
                      align="left"
                    >
                      {crop.crop_name}
                    </TableCell>
                    <TableCell align="left">{crop?.season}</TableCell>

                    <TableCell align="left">
                      <SwitchKey eachUser={crop} crop={crop} sendingActivityStatus={sendingCropDataActivityStatus} status={crop.is_active} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
      {/* <PasswordCanvas placement={"end"} name={"click"} /> */}
      <div id='randomDiv'></div>
    </Container>
  );
}
