import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OffCanvasLogs from "../../components/client/canvas/CanvasForLogs";
import Loader from "../../components/Loader/Loader";
import ActivityLogs from "../../components/Logs/ActivityLogs";
import HTTPService from "../../Services/HTTPService";
import { GetErrorHandlingRoute } from "../../Utils/Common";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import "./logs.css";


const Logs = () => {

    const [activityLogsData, setActivityLogsData] = React.useState([]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
    const [canvasData, setCanvasData] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
console.log("show",show)
    let navigate = useNavigate()
    

    const getAllLogs = () => {
        let url = UrlConstants.base_url + "main/activity_logs_list/";
        setLoader(true);
        HTTPService("GET", url, false, false, true, false)
          .then((res) => {
            setLoader(false);
            console.log("res in get all client", res);
            if (res.status === 200) {
                setActivityLogsData(res.data);
            }
          })
          .catch((err) => {
            console.log("err", err);
            setLoader(false);
            navigate(GetErrorHandlingRoute(err));
          });
      };
      console.log("activityLogsData",activityLogsData)
      
      const getCanvasData = (id) => {
        let url = UrlConstants.base_url + `main/activity_logs/?session=${id}`;
        setLoader(true);
        HTTPService("GET", url, false, false, true, false)

          .then((res) => {
            setLoader(false);
            console.log("res in get canvas data", res);
            if (res.status === 200) {
              setCanvasData(res.data);
              handleShow();
              // handleShow();
            }
          }
          )
          .catch((err) => {
            console.log("err", err);
            setLoader(false);
            handleShow();
            navigate(GetErrorHandlingRoute(err));
          });
      };


      useEffect(() => {
        getAllLogs();
        }, []);

    return (
      <Container className="client-manager-container">
        {loader ? <Loader /> : null}
            <ActivityLogs getCanvasData={getCanvasData}  activityLogsData={activityLogsData} />
            <OffCanvasLogs canvasData={canvasData} show={show} handleShow={handleShow} handleClose={handleClose} placement={"end"} />
        </Container>
    )
}

export default Logs;
