import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import "./displayClient.css";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Switch from "../toggle-button/Switch";
import HTTPService from "../../Services/HTTPService";
import SwitchKey from "../toggle-button/Switch";
import PasswordCanvas from "./canvas/PasswordCanvas";

export default function DisplayClient({ allClient, sendingActivityStatus }) {
  const [clientData, setClientData] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const toggleMore = () => {
    setShowMore(!showMore);
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "black",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "black",
    },
  }));

  function popupOverlayHandler() {
    let div = document.getElementById("popover-basic");
    console.log(div)
    if (div) div.classList.remove("show")
  }

  useEffect(() => {
    setClientData([...allClient]);
  }, [allClient]);
  let descriptionClass = "description-column";
  return (
    <Container className="display-client-container">

      <Row>
        <Col sx={12} sm={12} md={12} xl={12}>
          <Typography className="titleText" variant="h4">
            Client Details
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col sx={12} sm={12} md={12} xl={12}>
          <TableContainer component={Paper} onScroll={() => popupOverlayHandler()} className="table-container">
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="tablehead_bg_green">
                <TableRow>
                  <TableCell width="10%">Sl.No</TableCell>
                  <TableCell width="20%" align="left">
                    Name
                  </TableCell>
                  <TableCell width="18%" align="left">
                    Country
                  </TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell width="12%" align="left">
                    Active/Inactive
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientData.map((client, index) => (
                  <TableRow
                    key={index}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{client.client_name}</TableCell>
                    <TableCell align="left">{client.country.name}</TableCell>
                    <TableCell
                      className="description-column"
                      width="46%"
                      align="left"
                    >
                      {client.description.length > 50 ? (
                        // && showMore === false
                        <span>
                          {client.description.substring(0, 44)}...
                          <span
                            style={{ color: "blue" }}
                            onClick={toggleMore}
                          >
                            <BootstrapTooltip title={client.description}>
                              <span>more</span>
                            </BootstrapTooltip>
                          </span>
                        </span>
                      ) : (
                        // : client.description.length > 50 &&
                        //   showMore === true ? (
                        //   <span>
                        //     {client.description}{" "}
                        //     <span
                        //       style={{ color: "blue" }}
                        //       onClick={toggleMore}
                        //     >
                        //       {" "}
                        //       less{" "}
                        //     </span>
                        //   </span>
                        // )
                        client.description
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <SwitchKey eachUser={client} client={client} sendingActivityStatus={sendingActivityStatus} status={client.is_active} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
      <PasswordCanvas placement={"end"} name={"click"} />
      <div id='randomDiv'></div>
    </Container>
  );
}
