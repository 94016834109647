import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Container, Row, Col } from "react-bootstrap";
// import { getData, getCode } from "country-list";
import "../../Utils/common.css";
import "./addAdmin.css";
import HTTPService from "../../Services/HTTPService";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import {
  descriptionRegex,
  nameField,
  nameRegex,
  NO_SPACE_REGEX,
  validate,
} from "../../Utils/Common";
import RegexConstants from "../../Utils/Constants/RegexConstants";
import validator from "validator";

const AddAdmin = (props) => {
  // Create a form which accept name country and discription

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  // const [showToast, setShowToast] = useState(false);
  const [validEmail, setValidEmail] = useState(false);

  let submitBtnClassName = "button disabled-submit-btn";

  if (name && validEmail) {
    submitBtnClassName = "button submit-btn";
  }

  // function to toggel toast
  // const toggleToast = () => {
  //   setShowToast(!showToast);
  // };
  const handleSubmit = (e) => {
    // setShowToast(true);

    let data = {
      name: name,
      email: email,
      description: description,
    };
    console.log("data in submit", data);
    // let url = UrlConstants.base_url + "Admins/Admin/";
    props.addAdmin(data, e);
  };

  const handleCancel = () => {
    props.resetErrorMessage();
    setName("");
    setEmail("");
    setDescription("");
  };

  const handleName = (e) => {
    props.resetErrorMessage();
    let twoSpaceRegex = RegexConstants.TWO_SPACE_REGEX
    if(e.target.value[0]!==" "){
        setName(e.target.value.replace(twoSpaceRegex," "))
      }
  };

  const handleEmail = (e) => {
    props.resetErrorMessage();
    // check for valid email
    setValidEmail(validator.isEmail(e.target.value));

    if (validate(e.target.value, NO_SPACE_REGEX)) {
      setEmail(e.target.value);
    }
  };

  const handleDescription = (e) => {
    props.resetErrorMessage();
    let twoSpaceRegex = RegexConstants.TWO_SPACE_REGEX;
    if (e.target.value[0] !== " ") {
      setDescription(e.target.value.replace(twoSpaceRegex, " "));
    }
  };

  function onClickOfEnter() {
    if (window.event.keyCode == 13) {
      // console.log("ENTER PRESSED");
      window.event.preventDefault();
      window.event.stopPropagation();
      return false;
    }
  }

  useEffect(() => {
    // set all state empty
    setName("");
    setEmail("");
    setDescription("");
  }, [props.clearState]);

  console.log("props", props);

  return (
    <>
      <Container className="main-container">
        {/* {showToast ? (
          <CustomToast
            toggleToast={toggleToast}
            color={"#30572a"}
            backgroundColor={"#30572A"}
            text={"The Admin is added successfully."}
          />
        ) : null} */}
        <Row xs={12} lg={12} md={12} sm={12}>
          <Typography className="titleText" variant="h4">
            Add New Admin
          </Typography>
        </Row>
        <form>
          <Row container spacing={2} style={{ paddingRight: "15px" }}>
            <Col item xs={12} sm={6}>
              <TextField
                onChange={handleName}
                value={name}
                variant="standard"
                id="name"
                name="name"
                label="Admin Name"
                fullWidth
                autoComplete="given-name"
                error={props.AdminNameErrorMessage ? true : false}
                helperText={props.AdminNameErrorMessage}
              />
            </Col>
            <Col item xs={12} sm={6} className="admin_email">
              <TextField
                onChange={handleEmail}
                value={email}
                variant="standard"
                id="name"
                name="Admin Email"
                label="Admin Mail Id"
                fullWidth
                autoComplete="given-name"
                error={props.AdminEmailErrorMessage ? true : false}
                helperText={props.AdminEmailErrorMessage}
              />
            </Col>
            <Row className="description" item xs={12}>
              <Col item xs={12}>
                <TextField
                  onChange={handleDescription}
                  onKeyDown={onClickOfEnter}
                  value={description}
                  multiline
                  variant="standard"
                  id="description"
                  name="description"
                  label="Description"
                  fullWidth
                  autoComplete="shipping address-line1"
                  error={props.AdminDescriptionErrorMessage ? true : false}
                  helperText={props.AdminDescriptionErrorMessage}
                  inputProps={{ maxLength: 250 }}
                />
              </Col>
            </Row>

            <Row spacing={2}>
              <Col className="btn-container" item xs={12} sm={12}>
                <Button
                  className={submitBtnClassName}
                  variant="contained"
                  type="button"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={handleSubmit}
                  disabled={name && validEmail ? false : true}
                >
                  Submit
                </Button>
                <Button
                  className="button cancel-btn"
                  variant="outlined"
                  type="reset"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        </form>
      </Container>
      <hr className="divider" />
    </>
  );
};

export default AddAdmin;
