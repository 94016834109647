import React from "react";
import HomeNavBar from "../../components/home/HomeNavBar"
import Footer from "../../components/footer/footer";
import HomePage from "../../components/home/HomePage";
import ContactUs from "../../components/contact/ContactUs";
import { useState } from "react";

export default function Home() {
    const [isContact, setIsContact] = useState(false)
    return (
    <>
        <div className="center_keeping_conatiner">
            <HomeNavBar />
            {isContact ? <ContactUs setIsContact ={setIsContact}/> : <HomePage /> }
            <Footer isContact={isContact} setIsContact={setIsContact}/>
        </div>
    </>
    )
}