import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import styles from "./switch.module.css" // all css classes imported to be used as variable for className's
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import { styled } from '@mui/material/styles';
import { Tooltip } from 'react-bootstrap';
import { Switch } from '@mui/material';


function SwitchKey({ sendingActivityStatus, user, client, admin,  status, crop }) {
    
    // console.log("user in switch key", user)
    const [isActive, setActive] = useState(false)
    const [show, setShow] = useState(false)
    const handleChange = (toChangeOrNot) => {
        if (toChangeOrNot) {
            sendingActivityStatus(!isActive, admin ? admin : user ? user : crop ? crop : client);
            // setActive(!isActive)
        }
    }
    // The popover constant which will be displayed after user clicks on toggle button for Yes/No
    const popover = (
        <Popover className={`overlay-show ${styles.tooltipMainDiv}`} id="popover-basic" >
            <span className={styles.areYouSureText}>Are you sure of {isActive ? "inactivating" : "activating"} the
                {admin? " Admin" : user ? " user" : client ? " client" : crop ? " crop data for client" : ""}? </span>
            <span className={styles.yes_or_no_btn_span}>
                <button className={styles.no_btn} onClick={(props) => handleChange(false)}>No</button>
                <button className={styles.yes_btn} data-trigger="focus" onClick={(props) => handleChange(true)} >Yes</button>
            </span>
        </Popover >
    );

    // Button to be used in future if minor css is completed with this button
    // const AntSwitch = styled(Switch)(({ theme }) => ({
    //     width: 24,
    //     height: 12,
    //     padding: 0,
    //     display: "flex",
    //     "&:active": {
    //         "& .MuiSwitch-thumb": {
    //             width: 9
    //         },
    //         "& .MuiSwitch-switchBase.Mui-checked": {
    //             transform: "translateX(9px)"
    //         }
    //     },
    //     "& .MuiSwitch-switchBase": {
    //         padding: 2.25,
    //         "&.Mui-checked": {
    //             transform: "translateX(11px)",
    //             color: "green",
    //             "& + .MuiSwitch-track": {
    //                 opacity: 2,
    //                 border: "2px solid green",
    //                 backgroundColor: theme.palette.mode === "dark" ? "red" : "white"
    //             }
    //         }
    //     },
    //     "& .MuiSwitch-thumb": {
    //         boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    //         width: 7,
    //         height: 7,
    //         borderRadius: 7,
    //         transition: theme.transitions.create(["width"], {
    //             duration: 200
    //         })
    //     },
    //     "& .MuiSwitch-track": {
    //         borderRadius: 16 / 2,
    //         opacity: 1,
    //         backgroundColor:
    //             theme.palette.mode === "dark"
    //                 ? "rgba(255,255,255,.35)"
    //                 : "rgba(0,0,0,.25)",
    //         boxSizing: "border-box"
    //     }
    // }));

    useEffect(() => {

        // As soon as the client status changes the useEffect will be triggered to display the latest changes
        setActive(status)
    }, [client, user, admin, crop])
    // console.log("isActive", admin,isActive)
    return (<span className={styles.toggleButtonMainDiv}>
        <OverlayTrigger rootCloseEvent="click" rootClose={false} id="yes_or_no" trigger="focus" placement="auto" overlay={popover}>
            <Form.Check
                type="switch"
                id="custom-switch"
                checked={isActive}
                // onChange={() => setShow(!show)}
                onClick={() => document.getElementById("popover-basic").classList.add("show")}
                className={styles.switch_button_active_or_inactive}
            />
            {/* Switch to be used after minor css done -->*/}
            {/* <AntSwitch className={styles.switch_button_active_or_inactive} id="custom-switch" checked={isActive} onChange={() => setShow(!show)} inputProps={{ "aria-label": "ant design" }} /> */}
        </OverlayTrigger>
        {/* <div id='randomDiv' onClick={() => setShow(false)}></div> */}
    </span>
    );
}

export default SwitchKey;