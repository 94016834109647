const RegexConstants = {
    APLHABET_REGEX: /[A-Za-z]/,
    TEXT_REGEX: /^[A-Za-z]*$/,
    NUMBER_REGEX: /^[0-9]*$/,
    ORG_NAME_REGEX: /^$|^[A-za-z0-9](([^\s])+(\s)?)*$/,
    INVALID_ADDRESS_CHARACTERS: /[!@$%^*(){}\[\]:;"'?]/,
    PINCODE_REGEX: /^[0-9]{0,10}$/,
    WEBSITE_URL_REGEX:
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-zA-Z0-9]+\.[a-zA-Z]+(\/[a-zA-Z0-9#]+\/?)*$/,
    NEW_WEBSITE_REGEX: "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?", 
    NEW_C_WEBSITE_REGEX: "^(HTTP[S]?:\\/\\/(WWW\\.)?|FTP:\\/\\/(WWW\\.)?|WWW\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?",
    NO_SPACE_REGEX: /^([^\s])*$/,
    No_space: /^\S/,
    city_name: /^$|^[a-zA-Z][a-zA-Z ]*$/,
    address: /^$|^[a-zA-Z0-9#,][a-zA-Z0-9#, ]*$/,
    NEW_NAME_REGEX: /^([a-zA-Z0-9]+\s?)*$/,   //allows only one space and no spl characters allowed
    TWO_SPACE_REGEX: /[ ]{2,}/
  };
  
  export default RegexConstants;