import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  /*display: flex;
flex-direction: row;*/
  align-items: center;
  padding: 10px 94px;

  width: 1440px;
  height: 80px;

  background: #ffffff;
  border: 1px solid #cfcfcf;
`;

export const NavLink = styled(Link)`
  color: #746666;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 25px;
  /*margin-bottom: -5px;*/
  /*margin-top: 25px;*/
  margin-right: 25px;
  padding: 0px;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #30572a;
    font-weight: 500;
  }
  &.active > .boldimage {
    display: block;
  }
  &.active > .nonboldimage {
    display: none;
  }
  &:hover {
    text-decoration: none;
    color: #a3b0b8;
  }
  &.active:hover {
    color: #3d4a52;
  }

  font-family: "Avenir";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 138.69%;
  /* or 19px */

  /* headings */
  // color: #a3b0b8;
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  // margin-right: -24px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
display: flex;
align-items: center;
margin-right: 0px;
margin-top: 10px;
border-color: 1px solid #30572A;
border-radius: 4px
width: 192px
height: 42px 
/* Third Nav */
justify-content: flex-end;

@media screen and (max-width: 768px) {
	display: none;
}
`;

export const NavBtnLink = styled(Link)`
  background: #ffffff;
  padding: 11px 78px 12px 78px;
  color: #30572a;
  outline: none;
  border: 1px solid #30572a;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    /*transition: all 0.2s ease-in-out;
	background: #fff;
    color: #808080;*/
    color: #30572a;
    text-decoration: none;
  }

  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138.69%;
  /* or 19px */

  color: #30572a;
`;
