import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { Col, Container, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import HTTPService from "../../Services/HTTPService";
import parse from "html-react-parser";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import "./Homepage.css";
import { Container } from "react-bootstrap";
import Loader from "../Loader/Loader";
import CustomToast from "../../Utils/Toast/Toast";
import { Navigate, useNavigate } from "react-router-dom";
import { GetErrorHandlingRoute } from "../../Utils/Common";

export default function HomePage(props) {
  const [firstDescription, setFirstDescription] = useState(
    "<span >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in eprehenderit in voluptate v</span>"
  );
  const [secondDescription, setSecondDesription] = useState(
    "<span >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in eprehenderit in voluptate v</span>"
  );
  const [firstImage, setfirstImage] = useState("");
  const [secondImage, setSecondImage] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState(false);

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    HTTPService(
      "GET",
      UrlConstants.base_url + UrlConstants.main_url,
      false,
      false
    )
      .then((response) => {
        setLoader(false);
        console.log("home page res",response.data)
        if (response.data) {
          console.log(response.data);
          setfirstImage(
            "https://cimmyt.digitalgreen.org/be/media/images/image1.png"
            // response.data.image1
          );
          setSecondImage(
            // response.data.image2
            "https://cimmyt.digitalgreen.org/be/media/images/image2.png"
          );
          setFirstDescription(response.data.text1);
          setSecondDesription(response.data.text2);
        }
      })
      .catch((e) => {
        setLoader(false);
        navigate(GetErrorHandlingRoute(e))
        /*
        setToastText("Error while fetching the data");
        setShowToast(true);*/
      });
  }, []);

  const toggleToast = () => {
    setShowToast(!showToast);
  };

  return (
    <>
      {showToast ? (
        <CustomToast toggleToast={toggleToast} error={true} text={toastText} />
      ) : null}
      {loader ? <Loader /> : null}
      <div className="main-container">
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={firstImage} className="firstImage" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className="firstDescription">{parse(firstDescription)}</div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} style={{ marginTop: 100 }}>
            <div className="secondDescription">{parse(secondDescription)}</div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={secondImage} className="secondImage" />
          </Col>
        </Row>
      </div>
    </>
  );
}
