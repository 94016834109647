import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import { Container, Row, Col } from "react-bootstrap";
import { getData, getCode } from "country-list";
import "../../../Utils/common.css";
import "./addData.css";
import {
  descriptionRegex,
  nameRegex,
  validate,
  NO_SPACE_REGEX,
  nameField,
} from "../../../Utils/Common";
import HTTPService from "../../../Services/HTTPService";
import validator from "validator";
import RegexConstants from "../../../Utils/Constants/RegexConstants";
import UrlConstants from "../../../Utils/Constants/UrlConstants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RichTextEditor from "react-rte";

const AddData = (props) => {
  console.log("AddData props");
  // Create a form which accept name country and discription
  const [imageFile, setImageFile] = useState(null)
  const [imageFile2, setImageFile2] = useState(null)
  const [clientOrganization, setClientOrganization] = useState("");
  const [country, setCountry] = useState("");
  const [cropName, setCropName] = useState("");
  const [season, setSeason] = useState("");
  const [startingMonth, setStartingMonth] = useState("");
  const [endingMonth, setEndingMonth] = useState("");
  const [cropNameList, setCropNameList] = useState([]);
  const [score, setScore] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [scoreFeedback, setScoreFeedback] = useState([
    "Weak",
    "Weak",
    "Okay",
    "Good",
    "Strong",
  ]);
  const [allClient, setAllClient] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [seasonList, setSeasonList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [allClientName, setAllClientName] = useState([]);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [reTypePasswordInputType, setReTypePasswordInputType] =
    useState("password");
  const [cimmytText, setCimmytText] = useState("");
  const [cimmytTextContent, setCimmytTextContent] = useState(
    RichTextEditor.createValueFromString(cimmytText, "html")
  );
  const [cimmytImage, setCimmytImage] = useState("");
  const [cimmytImageName, setCimmytImageName] = useState("");
  const [digitalGreenText, setDigitalGreenText] = useState("");
  const [digitalGreenTextContent, setDigitalGreenTextContent] = useState(
    RichTextEditor.createValueFromString(digitalGreenText, "html")
  );
  const [digitalGreenImage, setDigitalGreenImage] = useState("");
  const [digitalGreenImageName, setDigitalGreenImageName] = useState("");

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      //   "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      //   "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  //   all season name

  //   all month name

  //   all crop name

  // const cropNameList = [
  //     "Wheat",
  //     "Rice",
  //     "Maize",
  //     "Sugarcane",
  //     "Barley",
  //     "Jowar",
  //     "Bajra",
  //     "Ragi",
  //     "Gram",
  //     "Mustard",
  //     "Soy"
  //     ];

  // const [showToast, setShowToast] = useState(false);

  const countries = getData();

  //   let submitBtnClassName = "button disabled-submit-btn";

  //   if (name && country && client && validEmail) {
  //     submitBtnClassName = "button submit-btn";
  //   }

  let dataDownloadBtnClassName = props.showDataDownload
    ? "submit-btn highlighted data-download-btn"
    : "cancel-btn unhighlighted data-download-btn";
  let adminProfileBtnClassName = props.showAdminProfile
    ? "submit-btn highlighted admin-profile-btn"
    : "cancel-btn unhighlighted admin-profile-btn";

  let homePageSettingClassName = props.showHomePageSetting
    ? "submit-btn highlighted home-page-setting-btn"
    : "cancel-btn unhighlighted home-page-setting-btn";

  const handlepassword = (e) => {
    let upper = /(?=.*?[A-Z])/;
    let lower = /(?=.*?[a-z])/;
    let spl = /(?=.*?[#?!@$%^&*-])/;
    let minLength = /.{8,}/;
    let total = 0;
    let arr = [
      e.target.value.match(upper),
      e.target.value.match(lower),
      e.target.value.match(spl),
      e.target.value.match(minLength),
    ];
    arr.map((item) => (item ? total++ : total));

    setScore(total);
    // if (e.target.value.match(upper) || e.target.value.match(lower) || e.target.value.match(spl)) {
    // }
    // if (e.target.value.match(upper) && e.target.value.match(lower)) {
    //     setScore(3)
    // }
    // if (e.target.value.match(upper) && e.target.value.match(lower) && e.target.value.match(spl)) {
    //     setScore(4)
    // }
    // console.log(score)
    setErrorMessage("");
    props.setNewPasswordError("");

    if (validate(e.target.value, NO_SPACE_REGEX)) {
      setPassword(e.target.value);
    }
  };

  const handlePasswordSubmit = () => {
    // if (password !== confirmPassword) {
    //   setErrorMessage("Password is not matching with New password");
    //   return;
    // }
    if (score < 4) {
      setErrorMessage("Password is not strong.");
      return;
    }
    props.handlePasswordSubmit(password);
    setScore(0);
  };

  console.log(score);

  const handleSubmit = (e) => {
    let payload = {
      crop_name: cropName,
      season: season,
      client: selectedClientId,
    };

    props.postAllData(payload);

    // console.log("on submit data",payload)
    // props.handleAddData(data);
  };

  const handleConfirmPassword = (e) => {
    setErrorMessage("");
    setConfirmPassword(e.target.value);
  };

  const setCountryListOnChange = (e) => {
    setClientOrganization(e.target.value);
    let clientCountry = allClient.filter((client, index) => {
      return client.client_name === e.target.value;
    });
    setCountry("");
    setCountryList(clientCountry);
    console.log("clientCountry");
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    // console.log(client.client_name, clientOrganization , client.country.name, country)
    let selectedClient = allClient.filter((client) => {
      // console.log("in fillter",client.client_name, clientOrganization , client.country.name, country)
      return (
        client.client_name == clientOrganization &&
        client.country.name == e.target.value
      );
    });

    setSelectedClientId(selectedClient[0].id);
    console.log("selectedClient.id", selectedClient[0].id);
  };

  let submitBtnClassName =
    score >= 4 && confirmPassword === password
      ? "button submit-btn"
      : "button disabled-submit-btn";
  let addDataSubmitBtnClassName =
    cropName && selectedClientId && season && country
      ? "button submit-btn"
      : "button disabled-submit-btn";

  useEffect(() => {
    setPassword("");
    setConfirmPassword("");
    setClientOrganization("");
    setCountry("");
    setSeason("");
    setCropName("");
    setScore(0);
  }, [props.resetInput, props.showDataDownload]);

  useEffect(() => {
    setPassword("");
    setConfirmPassword("");
    props.setNewPasswordError("");
    setScore(0);
  }, [props.showDataDownload]);

  const removeDuplicates = (arr) => {
    let dict = {};
    console.log("obj", arr);
    let fillteredArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (!dict[arr[i].client_name]) {
        fillteredArr.push(arr[i]);
        dict[arr[i].client_name] = 1;
      }
    }
    return fillteredArr;
  };

  const handleCimmytTextChange = (value) => {
    setCimmytTextContent(value);
    setCimmytText(value.toString("html"));
    console.log("cimmyt text", cimmytTextContent, cimmytText);
  };

  const handleDigitalGreenTextChange = (value) => {
    setDigitalGreenTextContent(value);
    setDigitalGreenText(value.toString("html"));
    console.log("cimmyt text", digitalGreenTextContent, digitalGreenText);
  };

  // console.log("RichTextEditor.createValueFromString(text, 'html')",RichTextEditor.createValueFromString(text, 'html'))
  // console.log('cuntries', countries)

  // console.log("props in download data", props);
  // console.log("cropNameList", cropNameList);

  const handleCimmytImageUplode = (e) => {
    let fileInput = document.getElementById("image-input");
    setImageFile(fileInput.files[0])
    // fileInput.onchange = () => {
    let selectedFile = fileInput.files[0];
    let reader = new FileReader();

    reader.onload = function () {
      let base64String = reader.result.replace("data:", "").replace(/^.+,/, "");

      let imageBase64Stringsep = base64String;

      // alert(imageBase64Stringsep);
      setCimmytImage(base64String);
      console.log("base64String", base64String);
    };
    reader.readAsDataURL(selectedFile);
    setCimmytImageName(selectedFile.name);

    console.log(selectedFile);
    console.log("image", e);
  };

  const handleDigitalGreenImageUplode = (e) => {
    let fileInput = document.getElementById("image2-input");
    // fileInput.onchange = () => {
    setImageFile2(fileInput.files[0])
      
    let selectedFile = fileInput.files[0];
    let reader = new FileReader();

    reader.onload = function () {
      let base64String = reader.result.replace("data:", "").replace(/^.+,/, "");

      let imageBase64Stringsep = base64String;

      // alert(imageBase64Stringsep);
      setDigitalGreenImage(base64String);
      console.log("base64String", base64String);
    };
    reader.readAsDataURL(selectedFile);
    setDigitalGreenImageName(selectedFile.name);

    console.log(selectedFile);
    console.log("image", e);
  };

  const handleCancel = () => {
    console.log("hit cancel");
    document.querySelector('#image-input').value = ''
    document.querySelector('#image2-input').value = ''
    setDigitalGreenImage('')
    setCimmytImage('')
    setDigitalGreenImageName('')
    setCimmytImageName('')
    setImageFile(null)
    setImageFile2(null)
    setCimmytTextContent(
      RichTextEditor.createValueFromString(props.homePageContent?.text1, "html")
    );
    setDigitalGreenTextContent(
      RichTextEditor.createValueFromString(props.homePageContent?.text2, "html")
    );
  };

  const handleSubmitInUpdateContent = () =>{
    let payload = new FormData()
    if(cimmytText) payload.append("text1", cimmytText) 
    if(digitalGreenText) payload.append("text2", digitalGreenText)
    if(imageFile !=null && typeof imageFile != "string") payload.append("image1", imageFile)
    if(imageFile2 !=null && typeof imageFile2 != "string") payload.append("image2", imageFile2)
    console.log("form data",payload,imageFile,imageFile2 )
    // let payload = {
    //   text1: cimmytText,
    //   text2: digitalGreenText,
    //   image1: cimmytImage,
    //   image2: digitalGreenImage
    // }

    props.updateHomePageContent(payload)
  }

  useEffect(() => {
    setCropNameList(props.cropName);
    setSeasonList(props.sessionName);
    setAllClient(props.allClient);
    setAllClientName(removeDuplicates(props.allClient));
  }, [props.cropName, props.sessionName, props.allClient]);

  useEffect(() => {
    setCimmytTextContent(
      RichTextEditor.createValueFromString(props.homePageContent?.text1, "html")
    );
    setDigitalGreenTextContent(
      RichTextEditor.createValueFromString(props.homePageContent?.text2, "html")
    );
  }, [props.homePageContent]);

  console.log("texttttt", cimmytText, digitalGreenText);
  console.log("html text", cimmytTextContent, digitalGreenTextContent);

  return (
    <>
      <Container className="main-container">
        <Row spacing={2} style={{ marginRight: "24px" }}>
          <Col className="change-password-btn-container" item xs={12} sm={12}>
            <Button
              className={dataDownloadBtnClassName}
              variant="contained"
              type="button"
              sx={{ mt: 3, ml: 1 }}
              onClick={() => {
                props.setShowDataDownload(true);
                props.setShowAdminProfile(false);
                props.setShowHomePageSetting(false);
              }}
            >
              Data download
            </Button>
            <Button
              className={adminProfileBtnClassName}
              variant="outlined"
              type="reset"
              sx={{ mt: 3, ml: 1 }}
              onClick={() => {
                props.setShowDataDownload(false);
                props.setShowAdminProfile(true);
                props.setShowHomePageSetting(false);
              }}
            >
              Admin profile
            </Button>
            <Button
              className={homePageSettingClassName}
              variant="outlined"
              type="reset"
              sx={{ mt: 3, ml: 1 }}
              onClick={() => {
                //  props.setShowHomePageSetting(!props.showHomePageSetting)
                props.setShowDataDownload(false);
                props.setShowAdminProfile(false);
                props.setShowHomePageSetting(true);
              }}
            >
              Home page settings
            </Button>
          </Col>
        </Row>
        {props.showDataDownload ? (
          <>
            <Row xs={12} lg={12} md={12} sm={12}>
              <Typography className="titleText" variant="h4">
                Add data
              </Typography>
            </Row>
            <form>
              <Row container spacing={2}>
                <Col style={{ paddingRight: "20px" }} item xs={12} sm={6}>
                  <FormControl variant="standard" className="country-input">
                    <InputLabel
                      className="require-input-label"
                      id="demo-simple-select-required-label"
                    >
                      Client Organization
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required-label"
                      value={clientOrganization}
                      onChange={(e) => setCountryListOnChange(e)}
                      label="Client Organization *"
                    >
                      {allClientName?.map((client, index) => (
                        <MenuItem key={index} value={client.client_name}>
                          {client.client_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col
                  style={{ paddingLeft: "10px", paddingRight: "25px" }}
                  item
                  xs={12}
                  sm={6}
                >
                  <FormControl variant="standard" className="country-input">
                    <InputLabel
                      className="require-input-label"
                      id="demo-simple-select-required-label"
                    >
                      Country
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required-label"
                      value={country}
                      onChange={(e) => {
                        handleCountryChange(e);
                        props.setCropNameErrorMessage(null);
                      }}
                      label="Country *"
                    >
                      {countryList?.map((client, index) => (
                        <MenuItem key={index} value={client.country.name}>
                          {client.country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>

                {/* Create two input box which accept email and client name  */}

                <Col
                  className="description"
                  style={{ paddingRight: "10px" }}
                  item
                  xs={12}
                  sm={6}
                >
                  <FormControl
                    variant="standard"
                    className="country-input"
                    error={props.cropNameErrorMessage ? true : false}
                    helperText={props.cropNameErrorMessage}
                  >
                    <InputLabel
                      className="require-input-label"
                      id="demo-simple-select-required-label"
                    >
                      Crop Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required-label"
                      value={cropName}
                      onChange={(e) => {
                        props.setCropNameErrorMessage("");
                        setCropName(e.target.value);
                      }}
                      label="Crop Name *"
                    >
                      {cropNameList?.map((crop, index) => (
                        <MenuItem key={index} value={crop}>
                          {crop}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText id="my-helper-text">
                      {props.cropNameErrorMessage}
                    </FormHelperText>
                  </FormControl>
                </Col>
                <Col
                  className="description"
                  style={{ paddingLeft: "10px", paddingRight: "18px" }}
                  item
                  xs={12}
                  sm={6}
                >
                  <FormControl variant="standard" className="country-input">
                    <InputLabel
                      className="require-input-label"
                      id="demo-simple-select-required-label"
                    >
                      Season Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required-label"
                      value={season}
                      onChange={(e) => setSeason(e.target.value)}
                      label="Season Name *"
                    >
                      {seasonList?.map((season, index) => (
                        <MenuItem key={index} value={season}>
                          {season}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>

                <Row spacing={2}>
                  <Col className="btn-container" item xs={12} sm={12}>
                    <Button
                      className={addDataSubmitBtnClassName}
                      disabled={
                        cropName && selectedClientId && season && country
                          ? false
                          : true
                      }
                      variant="contained"
                      sx={{ mt: 3, ml: 1 }}
                      onClick={handleSubmit}
                      //   disabled={(name && country && client && userEmail && validEmail) ? false : true}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Row>
            </form>
          </>
        ) : props.showAdminProfile ? (
          <div>
            <Row xs={12} lg={12} md={12} sm={12}>
              <Typography className="titleText" variant="h4">
                Change Password
              </Typography>
            </Row>
            <Row container spacing={2}>
              <Col style={{ paddingRight: "20px" }} item xs={12} sm={6}>
                <TextField
                  style={{}}
                  onChange={handlepassword}
                  value={password}
                  variant="standard"
                  type={passwordInputType}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() =>
                          passwordInputType === "password"
                            ? setPasswordInputType("text")
                            : setPasswordInputType("password")
                        }
                        position="end"
                      >
                        {passwordInputType === "password" ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  id="name"
                  name="name"
                  label="Enter New Password"
                  fullWidth
                  autoComplete="given-name"
                  error={props.newPasswordError ? true : false}
                  helperText={props.newPasswordError}
                />
                <span className="password_strength_checker_main_div password_strength_checker_admin_change_password">
                  <span className="password_strength_checker_color_main_box">
                    <div
                      style={{
                        height: "3px",
                        width: "20px",
                        background: score >= 1 ? "#30572A" : "#99DA8F",
                      }}
                    ></div>
                    <div
                      style={{
                        height: "3px",
                        width: "20px",
                        background: score >= 2 ? "#30572A" : "#99DA8F",
                        marginLeft: "5px",
                      }}
                    ></div>
                    <div
                      style={{
                        height: "3px",
                        width: "20px",
                        background: score >= 3 ? "#30572A" : "#99DA8F",
                        marginLeft: "5px",
                      }}
                    ></div>
                    <div
                      style={{
                        height: "3px",
                        width: "20px",
                        background: score >= 4 ? "#30572A" : "#99DA8F",
                        marginLeft: "5px",
                      }}
                    ></div>
                    {/* <PasswordStrengthBar onChangeScore={(score, callback) => { */}
                    {/* }} minLength={8} scoreWordStyle={{ fontSize: "10px", margin: "0px", color: "rgb(137, 135, 146)", textAlign: "left" }} className="password_strength_main" style={{ width: "95px" }} barColors={["#99DA8F", score == 1 ? "#30572A" : "#99DA8F", score == 2 ? "#30572A" : "#99DA8F", score == 3 ? "#30572A" : "#99DA8F", score == 4 ? "#30572A" : "#99DA8F"]} password={password} />  */}
                  </span>
                  <p className="password_strength_checker_text_box">
                    {scoreFeedback[score]}
                  </p>
                </span>
              </Col>
              <Col
                style={{ paddingLeft: "10px", paddingRight: "25px" }}
                item
                xs={12}
                sm={6}
              >
                <TextField
                  // onChange={handleEmail}
                  onKeyDown={(e) => {
                    if (e.key == " ") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => handleConfirmPassword(e)}
                  value={confirmPassword}
                  type={reTypePasswordInputType}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() =>
                          reTypePasswordInputType === "password"
                            ? setReTypePasswordInputType("text")
                            : setReTypePasswordInputType("password")
                        }
                        position="end"
                      >
                        {reTypePasswordInputType === "password" ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  id="name"
                  name="Email"
                  label="Re-type password"
                  fullWidth
                  autoComplete="given-name"
                  error={errorMessage ? true : false}
                  helperText={errorMessage}
                />
              </Col>
            </Row>
            <Row>
              <p className="guidance_text">
                Password should be:
                <br />
                Min 8 characters | Min 1 lower case and min 1 upper case | Min 1
                special characters.
              </p>
            </Row>
            <Row spacing={2}>
              <Col className="btn-container" item xs={12} sm={12}>
                <Button
                  className={submitBtnClassName}
                  variant="contained"
                  type="button"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={handlePasswordSubmit}
                  disabled={score >= 4 && confirmPassword ? false : true}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          <>
            <div>
              <Row xs={12} lg={12} md={12} sm={12}>
                <Typography className="titleText" variant="h4">
                  For CIMMYT
                </Typography>
              </Row>
              <Row xs={12} lg={12} md={12} sm={12}>
                <RichTextEditor
                  toolbarConfig={toolbarConfig}
                  value={cimmytTextContent}
                  onChange={handleCimmytTextChange}
                  required
                  id="body-text"
                  name="bodyText"
                  type="string"
                  multiline
                />
              </Row>
              <Row>
                <Col
                  xs={12}
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ display: "flex" }}
                >
                  <TextField
                    // onChange={handleEmail}
                    onKeyDown={(e) => {
                      if (e.key == " ") {
                        e.preventDefault();
                      }
                    }}
                    className="image-name-input-box"
                    value={cimmytImageName}
                    // value={}
                    type="text"
                    variant="standard"
                    id="name"
                    name="Email"
                    label="Choose image (PNG, SVG, JPG, JPEG)"
                    fullWidth
                    autoComplete="given-name"
                    error={errorMessage ? true : false}
                    helperText={errorMessage}
                  />
                  <Button
                    variant="outlined"
                    component="label"
                    className="image-input-button-classname"
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      id="image-input"
                      accept="image/png, image/svg, image/jpeg, image/jpg" 
                      onChange={(e) => handleCimmytImageUplode(e)}
                    />
                  </Button>
                </Col>
              </Row>
              <Row></Row>
            </div>
            <div>
              <Row xs={12} lg={12} md={12} sm={12}>
                <Typography className="titleText" variant="h4">
                  For Digital Green
                </Typography>
              </Row>
              <Row xs={12} lg={12} md={12} sm={12}>
                <RichTextEditor
                  toolbarConfig={toolbarConfig}
                  value={digitalGreenTextContent}
                  onChange={handleDigitalGreenTextChange}
                  required
                  id="body-text"
                  name="bodyText"
                  type="string"
                  multiline
                />
              </Row>
              <Row>
                <Col
                  xs={12}
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ display: "flex" }}
                >
                  <TextField
                    // onChange={handleEmail}
                    onKeyDown={(e) => {
                      if (e.key == " ") {
                        e.preventDefault();
                      }
                    }}
                    className="image-name-input-box"
                    value={digitalGreenImageName}
                    // value={}
                    type="text"
                    variant="standard"
                    id="name"
                    label="Choose image (PNG, SVG, JPG, JPEG)"
                    fullWidth
                    autoComplete="given-name"
                  />
                  <Button
                    variant="outlined"
                    component="label"
                    className="image-input-button-classname"
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      id="image2-input"
                      accept="image/png, image/svg, image/jpeg, image/jpg" 
                      onChange={(e) => handleDigitalGreenImageUplode(e)}
                    />
                  </Button>
                </Col>
              </Row>
              <Row></Row>
            </div>
            <Row spacing={2}>
              <Col className="btn-container" item xs={12} sm={12}>
                <Button
                  className="button submit-btn"
                  variant="contained"
                  type="button"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={handleSubmitInUpdateContent}
                  // disabled={score >= 4 && confirmPassword ? false : true}
                >
                  Submit
                </Button>
                <Button
                  className="button cancel-btn"
                  variant="outlined"
                  type="reset"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default AddData;
