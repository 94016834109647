import React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Col, Row } from "react-bootstrap";
import "./Login.css";
import cimmytlogo from "../../Assets/Image/CIMMYTLogologin.svg";
import loginimg from "../../Assets/Image/loginimg.svg";
import dglogo from "../../Assets/Image/dglogo.svg";
import HTTPService from "../../Services/HTTPService";
import UrlConstants from "../../Utils/Constants/UrlConstants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import Loader from "../Loader/Loader";
import {
  setRoleLocal,
  setTokenLocal,
  getTokenLocal,
  setUserId,
  getUserLocal,
  setUserMapId,
  setOrgId,
  isLoggedInUserAdmin,
  isLoggedInUserNormal,
  getRoleLocal,
  getUserMapId,
  GetErrorKey,
  validateInputField,
  isNormalRoute,
  setUserIdLocal,
  GetErrorHandlingRoute,
  setSesssionIdLocal,
  validate,
  NO_SPACE_REGEX,
  isLoggedInSuperUser,
} from "../../Utils/Common";
import RegexConstants from "../../Utils/Constants/RegexConstants";
import LocalStorageConstants from "../../Utils/Constants/LocalStorageConstants";
import AdminRoutes from "../../layout/Admin";
import CustomToast from "../../Utils/Toast/Toast";
import PasswordCanvas from "../client/canvas/PasswordCanvas";
import Success from "../Success/Success";
import ChangePassword from "./ChangePassword";
import { createTheme } from "@mui/system";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment } from "@mui/material";

const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "red" },
      },
    },
  },
});
const useStyles = {
  image1: {
    width: "286px",
    height: "70px",
    "margin-top": "50px",
    "margin-left": "50px",
  },
  formStyle: { height: "6%", marginTop: "198px" },
  formStyleMailID: { height: "6%", marginTop: "50px" },
  secondField: { height: "27px", marginTop: "35px" },
  forgotpasswordfield: { marginTop: "54px", width: "10%" },
  buttonstyle: { marginTop: "56px" },
  lastImage: { marginLeft: "93px" },
  cimmytLogoMainDiv: { margin: "30px auto 0px auto", textAlign: "center" },
  bottomLogoDiv: { margin: "200px auto 30px auto", textAlign: "center" },
};

export default function Login(props) {
  const [usernameoremail, setUserNameorEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailIdForMail, setEmailIdForMail] = useState("");
  const [errormessage, setErrorMessage] = useState("");
  const [forgotMailIdError, setForgotMailIdError] = useState("");
  const [usernameerrormessage, setUserError] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isloader, setIsLoader] = useState(false);
  const [valid, setValid] = useState(false);

  const toggleToast = () => {
    setShowToast(!showToast);
  };
  const [isOnBoard, setIsOnBoard] = useState(false);

  const [passwordInputType, setPasswordInputType] = useState("password")

  const landingPageUserCredentialsCheckFromLocal = () => {
    if (isLoggedInUserAdmin()) {
      navigate("/admin/clientmanager");
    } else if (isLoggedInUserNormal()) {
      navigate("/user/datadownload");
    } else if (isLoggedInSuperUser()) {
      navigate("/superuser/clientmanager");
    }
  };
  useEffect(() => {
    landingPageUserCredentialsCheckFromLocal();
  }, []);

  const handleemail = (e) => {
    setUserError("")
    e.target.value = e.target.value.trim();
    console.log(e.target.value);
    const valid = validator.isEmail(e.target.value);
    console.log(valid);
    if (validate(e.target.value, NO_SPACE_REGEX)) {
      setUserNameorEmail(e.target.value);
    }
    setValid(valid);
  };
  const handlepassword = (e) => {
    // e.preventDefault();
    setPasswordError("");
    e.target.value = e.target.value.trim();
    // console.log(e.target.value)
    var password = e.target.value;
    // const valid = validator.isStrongPassword(password);
    // console.log(valid);
    // const finalPassword = password.trim()
    setPassword(password);
    // console.log(finalPassword)
  };

  const handleSubmit = (e) => {
    // e.preventDefault();

    //   var bodyFormData = new FormData();
    //   bodyFormData.append("email", usernameoremail)
    //   bodyFormData.append("password", password)
    //   console.log(password)
    //   console.log(usernameoremail)
    let payload = {
      email: usernameoremail,
      password: password,
      // role: isNormalRoute(location.pathname) ? LocalStorageConstants.ROLES.CIMMYT_NORMAL_ROOT
      //     : LocalStorageConstants.ROLES.CIMMYT_ADMIN
    };
    setForgotMailIdError("");
    setUserError("");
    setPasswordError("");
    setIsLoader(true);
    HTTPService(
      "POST",
      UrlConstants.base_url + UrlConstants.users,
      payload,
      false,
      false
    )
      .then((response) => {
        setUserNameorEmail("");
        // setPassword("")
        setIsLoader(false);
        console.log("response");
        console.log("login details", response.data);
        console.log(response.data.email);
        // console.log(response.data.password)
        if (response.status === 201) {
          setIsOnBoard(response.data.is_onboarded);
          setTokenLocal(response.data.access_token);
          if (response.data.is_onboarded) {
            localStorage.setItem(
              "email_id",
              JSON.stringify(response.data.email)
            );
            setUserNameorEmail(response.data.email);
            setPassword(response.data.password);
            setRoleLocal(response.data.role);
            setUserIdLocal(response.data.id);
            setSesssionIdLocal(response.data?.session);
            console.log("response.data.role", response.data.role);

            if (response.data.role) {
              if (isLoggedInSuperUser()) {
                console.log("response.data.role", response.data.role);
                navigate("/superuser/adminmanager");
              } else if (isLoggedInUserAdmin()) {
                navigate("/admin/clientmanager");
              } else if (isLoggedInUserNormal()) {
                navigate("/user/datadownload");
              } else {
                navigate("/login");
              }
            }
            
          } else {
            console.log(response.data.email);
            localStorage.setItem(
              "cimmyt_emailId",
              JSON.stringify(response.data.email)
            );
            localStorage.setItem(
              "temp_token",
              JSON.stringify(response.data.access_token)
            );
            navigate("/change_password");
          }
        } else {
          //error
        }
      })
      .catch((e) => {
        setIsLoader(false);
        setPassword("");
        console.log("err", e, e.response.status);
        if (
          e.response != null &&
          e.response != undefined &&
          e.response.status === 401
        ) {
          setPasswordError(
            e.response.data && e.response.data.password
              ? e.response.data.password
              : "Incorrect password, please enter the correct password"
          );
        } else if (
          e.response != null &&
          e.response != undefined &&
          e.response.status === 403
        ) {
          setUserError(
            e.response.data && e.response.data.email
              ? e.response.data.email
              : "User not registered"
          );
        } else if (
          e.response != null &&
          e.response != undefined &&
          e.response.status === 400
        ) {
          setUserError(
            e.response.data && e.response.data.email
              ? e.response.data.email
              : "Enter a valid email address."
          );
        } else {
          navigate(GetErrorHandlingRoute(e));
        }
      });
  };

  function handleKeyPress() {
    if (window.event.keyCode == 13) {
        // console.log("ENTER PRESSED");
        window.event.preventDefault();
        handleSubmit()
    }
}

  useEffect(() => {}, [passworderror, usernameerrormessage]);

  return (
    <>
      {showToast ? (
        <CustomToast
          error={true}
          text={"Something went wrong"}
          toggleToast={toggleToast}
        />
      ) : null}
      {isloader ? (
        <Loader />
      ) : (
        <div className="center_keeping_conatiner">
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div>
                <img src={loginimg} alt="login" />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <img style={useStyles.image1} src={cimmytlogo} alt="cimmyt" />

              <Row style={useStyles.formStyle}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    required
                    id="standard-basic"
                    label="User name"
                    type="email"
                    
                    // inputProps={{inputMode: "email", pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"}}
                    variant="standard"
                    className="username"
                    value={usernameoremail}
                    onChange={handleemail}
                    onKeyDown={(e) => {
                      if (e.key == " ") {
                        e.preventDefault();
                      }
                      handleKeyPress()
                    }}
                    error={usernameerrormessage ? true : false}
                    helperText={usernameerrormessage}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  style={useStyles.secondField}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <TextField
                    required
                    id="standard-basic"
                    label="Password"
                    type={passwordInputType}
                    variant="standard"
                    className="password"
                    // children={<VisibilityIcon/>}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment onClick={() => passwordInputType === "password" ? setPasswordInputType("text") : setPasswordInputType("password")}  position="end">
                          {passwordInputType === "password" ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                        </InputAdornment>
                      ),
                    }}
                    onChange={handlepassword}
                    onKeyDown={(e) => {
                      if (e.key == " ") {
                        e.preventDefault();
                      }
                      handleKeyPress()
                    }}
                    error={passworderror ? true : false}
                    helperText={passworderror}
                  />
                </Col>
              </Row>
              <Row style={{ textAlign: "right", width: "340px" }}>
                <Col>
                  <button
                    onClick={() => {
                      setEmailIdForMail("");
                      setForgotMailIdError("");
                      setUserError("");
                      setPasswordError("");
                      setUserNameorEmail("");
                      setPassword("");
                      // setForgotPasswordMailIdState(true)
                      navigate("/forgot_password");
                    }}
                    style={useStyles.forgotpasswordfield}
                    className="forgotpassword"
                  >
                    Forgot Password?
                  </button>
                </Col>
              </Row>
              <Row></Row>

              <Row>
                <div style={useStyles.buttonstyle}>
                  {usernameoremail &&
                  password &&
                  password.length >= 8 &&
                  valid ? (
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      type="submit"
                      className="submitbtn"
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      disabled
                      type="submit"
                      className="submitbtn disabled-submit-btn-login"
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ marginTop: "136px" }}
                >
                  <img
                    style={useStyles.lastImage}
                    className="image"
                    src={dglogo}
                    alt="digitalgreen"
                  />
                </Col>
              </Row>

              {/* </div> */}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
